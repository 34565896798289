import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons'

const Star = ({ readOnly, selected, index, color, noPointers, size, margin }) => {
    const onSelect = () => {
        if (readOnly) {
            return
        }
        selected(index)
    }

    return (
        <FontAwesomeIcon
            onClick={onSelect}
            icon={faStar}
            style={{
                cursor: 'pointer',
                color,
                display: 'inline-block',
                width: `${size}px`,
                height: `${size}px`,
                margin: `${margin || 5}px`,
                pointerEvents: noPointers ? 'none' : 'auto',
            }}
        />
    )
}

Star.defaultProps = {
    selected: () => {},
    readOnly: false,
    size: 30,
}

Star.propTypes = {
    selected: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    size: PropTypes.number,
}

export default Star
