import React from 'react'
import styled from 'styled-components'
import { withStyles, WithStyles } from '@material-ui/core'

import { Block } from '../Results/ResultsDefinition'
import BionicModal from '../Bionic/BionicModal'

type Props = {
    block: Block
    onClose: () => void
} & WithStyles

const styles = {
    chartNumbers: {
        position: 'absolute',
        top: '100%',
        marginTop: 8,
        fontSize: 15,
        lineHeight: 1,
        transform: 'translateX(-50%)',
        fontFamily: 'Kelson',
        letterSpacing: -1,
        '@media only screen and (max-width: 768px)': {
            fontSize: 13,
            marginTop: 4,
        },
    },
    separator: {
        height: 1,
        background: '#425566',
        border: 0,
        marginBottom: 32,
        '@media only screen and (max-width: 768px)': {
            display: 'none',
        },
    },
}

const Text = styled.div`
    display: flex;
    margin-bottom: 32px;
    font-size: 16px;
`

function SimpleBionicModal({ block, onClose, classes }: Props) {
    return (
        <BionicModal block={block} onClose={onClose}>
            <Text>{block.text}</Text>
        </BionicModal>
    )
}

// @ts-ignore
export default withStyles(styles)(SimpleBionicModal)
