import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, ButtonBase } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faClipboardListCheck,
    faCalendarAlt,
    faTimes,
    faBuilding,
    faEllipsisH,
    faStickyNote,
} from '@fortawesome/pro-light-svg-icons'
import ResultBar from '../Results/ResultBar'
import { StyledButton } from '../Styles'
import SimpleDialog from '../SimpleDialog'

const StyledContainer = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 210px;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: ${props => props.theme.shadowGrey};
    cursor: pointer;

    .card-header {
        text-transform: none;
        padding: 0;
        font-weight: lighter;
        width: 100%;
    }

    .top-row {
        display: flex;
        align-items: center;
        height: 70px;
        padding: 10px;
        padding-left: 16px;
        border: 1px solid ${props => props.theme.borderColor};
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-image: ${props => props.theme.surveyCardHeaderBackground};
        background-color: ${props => props.theme.mainBackground};
        text-align: left;
        font-size: 20px;
        color: ${props => props.theme.mainTextColor};

        .text {
            padding-top: 2px;
            font-size: 24px;
        }
    }

    .row {
        padding: 12px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: left;
        font-size: 18px;

        .icon {
            font-size: 25px;
            color: ${props => props.theme.secondaryTextColor};
        }

        .text {
            padding-left: 10px;
            line-height: 32px;
            color: ${props => props.theme.secondaryTextColor};
        }
    }

    .row-button {
        display: flex;
        justify-content: center;
    }

    .delete-icon {
        display: block;
        width: 24px;
        height: 24px;
        margin-left: auto;
        border-radius: 50%;
        background-color: ${props => props.theme.deleteIconBackground};
    }

    .notes {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
    }

    .survey-notes-title {
        font-weight: bold;
        font-size: 24px;
    }
`

const formatDate = date => {
    const d = new Date(date)
    let month = `${d.getMonth() + 1}`
    let day = `${d.getDate()}`
    const year = d.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`
    return [day, month, year].join('.')
}

function reducer(state, action) {
    if (action.type === 'createCard') {
        const { presurvey, survey } = action.item
        let card = {}
        let date = null
        if (survey) {
            date = survey.dateEnd
            const {
                results: { userScore = null, descriptor = null },
            } = presurvey.profile
            card.score = userScore
            if (card.score < 0) {
                card.score = 'N/A'
            }
            card.descriptor = descriptor
            if (card.score === 'N/A') {
                card.descriptor = ''
            }
        }

        if (!survey && presurvey) {
            date = presurvey.lastUpdated
        }

        if (presurvey.profile) {
            card.blockStats = presurvey.profile.blockStats
            card.companyProfile = {
                industry: presurvey.profile.industry,
                location: presurvey.profile.location,
                companyName: presurvey.profile.company_name,
                surveyNote: presurvey.profile.note,
            }
        }

        card.date = formatDate(date)

        return { card }
    }

    return state
}

const HistoryCard = props => {
    const [showNotes, setShowNotes] = useState(false)
    const intialState = { card: { companyProfile: {} } }
    const [state, dispatch] = useReducer(reducer, intialState)
    const { historyItem, readOnly } = props

    useEffect(() => {
        dispatch({ type: 'createCard', item: historyItem })
    }, [historyItem, dispatch])

    const continueSurvey = () => {
        const { continueSurvey, historyItem } = props
        continueSurvey(historyItem)
    }

    const deleteSurvey = () => {
        const { deleteSurvey, historyItem } = props
        deleteSurvey(historyItem)
    }

    const openDialog = () => {
        setShowNotes(true)
    }

    const closeDialog = () => {
        setShowNotes(false)
    }

    const { score, descriptor, companyProfile, blockStats, date } = state.card
    return (
        <StyledContainer>
            <Grid container spacing={0} className="completed">
                <Grid container spacing={0} className="top-row">
                    {typeof score === 'number' ? (
                        <React.Fragment>
                            <Grid item xs={2}>
                                <div className="text">{score}</div>
                            </Grid>
                            <Grid item xs={8}>
                                <div className="text">{descriptor}</div>
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={10}>
                                <div className="text">Status Unfinished</div>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={2}>
                        {!readOnly && (
                            <ButtonBase className="delete-icon" onClick={deleteSurvey}>
                                <FontAwesomeIcon icon={faTimes} />
                            </ButtonBase>
                        )}
                    </Grid>
                </Grid>
                {typeof score === 'number' && descriptor !== '' ? (
                    <Grid item xs={12} style={{ height: '40px' }}>
                        <div style={{ padding: '15px 15px 0 15px' }}>
                            <ResultBar fill={score !== 'N/A' ? score : 0} />
                        </div>
                    </Grid>
                ) : (
                    <React.Fragment>
                        {!blockStats ? (
                            <Grid container spacing={0} className="row">
                                <Grid item xs={1} className="icon">
                                    <FontAwesomeIcon icon={faClipboardListCheck} />
                                </Grid>
                                <Grid item xs={11} className="text">
                                    Profile incomplete
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={0} className="row">
                                <Grid
                                    item
                                    xs={1}
                                    className="icon"
                                    style={{
                                        fontSize: '16px',
                                        paddingTop: '7px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {`${blockStats.completed}/${blockStats.total}`}
                                </Grid>
                                <Grid item xs={11} className="text">
                                    Blocks Completed
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                <Grid container spacing={0} className="row">
                    <Grid item xs={1} className="icon">
                        <FontAwesomeIcon icon={faBuilding} />
                    </Grid>
                    <Grid item xs={11} className="text">
                        {companyProfile.companyName
                            ? companyProfile.companyName
                            : 'Unknown company'}
                    </Grid>
                </Grid>
                <Grid container spacing={0} className="row">
                    <Grid item xs={1} className="icon">
                        <FontAwesomeIcon icon={faStickyNote} />
                    </Grid>
                    <Grid item xs={10} className="text">
                        <div className="notes">
                            {companyProfile.surveyNote ? companyProfile.surveyNote : 'No notes'}
                        </div>
                    </Grid>
                    <Grid item xs={1} className="icon">
                        {companyProfile.surveyNote && (
                            <FontAwesomeIcon icon={faEllipsisH} onClick={openDialog} />
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={0} className="row">
                    <Grid item xs={1} className="icon">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </Grid>
                    <Grid item xs={11} className="text">
                        {date}
                    </Grid>
                </Grid>
                <Grid item xs={12} className="row row-button">
                    <StyledButton customClass="narrow large" onClick={continueSurvey}>
                        {blockStats && blockStats.completed / blockStats.total === 1
                            ? 'View Results'
                            : 'Continue Survey'}
                    </StyledButton>
                </Grid>
            </Grid>
            <SimpleDialog dialogOpen={showNotes} closeDialog={closeDialog}>
                <div style={{ fontWeight: 'bold', fontSize: '24px' }}>Survey Notes</div>
                <br />
                {companyProfile.surveyNote}
            </SimpleDialog>
        </StyledContainer>
    )
}

HistoryCard.defaultProps = {
    readOnly: false,
    historyItem: {},
    continueSurvey: () => {},
    deleteSurvey: () => {},
}

HistoryCard.propTypes = {
    readOnly: PropTypes.bool,
    historyItem: PropTypes.object,
    continueSurvey: PropTypes.func,
    deleteSurvey: PropTypes.func,
}

export default HistoryCard
