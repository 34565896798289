import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Collapse, Button, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

import PreResultsList from './PreResultsList.tsx'
import PreResultsSlides from './PreResultsSlides'
import WelcomeMsg from '../Elements/WelcomeMsg.tsx'
import { StyledButton } from '../../Styles'
import BionicBox from '../../BionicBox/BionicBox'

const StyledContainer = styled(BionicBox)`
    position: relative;
    border: 1px solid ${props => props.theme.preResultsBorder};
    background-color: ${props => props.theme.preResultsHeadBackground};
    background-image: none;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;

    @media only screen and (max-width: 400px) {
        border: none;
    }

    .slides-wrapper-top {
        position: relative;
    }

    .slides-wrapper-bottom {
        position: relative;
        z-index: 0;
        display: inline-block;
        height: 250px;
    }

    .pre-results-title {
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .pre-results-subtitle {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .best-practice-title {
        margin-bottom: 25px;
        font-size: 18px;
        font-weight: bold;
    }

    .best-practice-action {
        width: 100%;
        padding: 15px;
        text-align: center;
        text-transform: none !important;
        font-size: 0.8rem !important;

        .text {
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
            color: ${props => props.theme.mainTextColor};
        }

        .icon {
            margin-left: 10px;
            color: ${props => props.theme.mainTextColor};
        }
    }

    .divider {
        height: 1px;
        background-color: ${props => props.theme.preResultsBorder};
    }

    .mobile-divider {
        margin-bottom: 25px;
    }

    .collapse {
        font-family: 'Proxima Nova', sans-serif;
        display: inline-block;
        padding: 0;
        width: 100% !important;
    }

    .best-practice {
        height: 100%;
        margin-right: 15px;
        margin-bottom: 15px;

        .title {
            font-weight: bold;
            font-size: 1.3rem;
        }

        .text {
            font-size: 1.2rem;
        }
    }

    .best-practice-container {
        position: relative;
    }

    .final-btn-wrapper {
        padding: 15px 15px 0 15px;
    }

    .tablet-only {
        display: none;
    }

    @media only screen and (min-width: 660px) {
        .slides-wrapper-bottom {
            height: 250px;
        }

        .slides-container {
            position: relative;
        }

        .best-practice-container {
            min-height: 230px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .tablet-only {
            display: inherit;
        }

        .mobile-only {
            display: none !important;
        }

        .slides-container-border {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 9;
            width: 1px;
            margin-right: 20px;
            background-color: ${props => props.theme.preResultsDivider};
        }
    }
`

class PreResultsContainer extends Component {
    state = {
        storyOpen: false,
        preResultsReady: 0,
    }

    componentDidMount() {
        this.currentBlockAndSlideIndex()
        const storyOpen = window.innerWidth >= 660
        this.setState({
            preResultsReady: 1,
            storyOpen,
        })
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { currentBlock } = this.state
        if (
            !currentBlock ||
            (newProps.currentBlock && newProps.currentBlock.blockId !== currentBlock.blockId)
        ) {
            this.currentBlockAndSlideIndex()
        }
    }

    currentBlockAndSlideIndex = () => {
        const { blocks, currentBlock } = this.props
        let slideIndex = 0

        if (currentBlock) {
            for (let i = 0; i < blocks.length; i += 1) {
                if (currentBlock.blockId === blocks[i].blockId) {
                    slideIndex = i
                }
            }
        }
        this.setState({ currentBlock, slideIndex })
    }

    toggleBestPractice = () => {
        const { storyOpen } = this.state
        this.setState({
            storyOpen: !storyOpen,
        })
    }

    updateCurrentBlock = (currentBlock, slideIndex) => {
        this.setState({ currentBlock, slideIndex })
    }

    updateCurrentBlockAndSlideIndex = (currentBlock, slideIndex) => {
        this.setState({ slideIndex, currentBlock })
    }

    select = block => {
        const { select } = this.props
        select(block)
    }

    render() {
        const { blocks, goToResults, profile, className } = this.props
        const { storyOpen, currentBlock, slideIndex, preResultsReady } = this.state
        // check to see if any blocks have preresults
        const completedBlocks = blocks.filter(b => b.preresults)
        const anyCompleted = completedBlocks.length > 0
        const allCompleted = completedBlocks.length >= blocks.length
        return (
            <>
                {anyCompleted ? (
                    <StyledContainer className={className} style={{ opacity: preResultsReady }}>
                        <Grid container>
                            <Grid item xs={12}>
                                {allCompleted && (
                                    <div className="final-btn-wrapper">
                                        <StyledButton onClick={goToResults} customClass="narrow">
                                            FINAL RESULTS
                                        </StyledButton>
                                    </div>
                                )}
                                <div className="pre-results-title">Pre Results</div>
                            </Grid>

                            <Grid item xs={12} sm={7}>
                                <div className="slides-container">
                                    <div className="pre-results-subtitle">
                                        Compared to your industry worldwide
                                    </div>
                                    <PreResultsList
                                        blocks={blocks}
                                        currentBlock={currentBlock}
                                        updateCurrentBlock={this.updateCurrentBlockAndSlideIndex}
                                    />
                                    <div className="slides-wrapper-top">
                                        <PreResultsSlides
                                            profile={profile}
                                            blocks={blocks}
                                            updateCurrentBlock={this.updateCurrentBlock}
                                            slideIndex={slideIndex}
                                            select={this.select}
                                        />
                                    </div>
                                    <div className="slides-wrapper-bottom" />
                                    <div className="tablet-only slides-container-border" />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <div className="best-practice-container">
                                    <div
                                        className="divider mobile-only mobile-divider"
                                        style={{ display: storyOpen ? 'inherit' : 'none' }}
                                    />
                                    <div className="best-practice-title">Best Practice Story</div>
                                    <Collapse in={storyOpen} className="collapse">
                                        {currentBlock && currentBlock.preresults ? (
                                            <div className="best-practice">
                                                {currentBlock.bestPractices[0] && (
                                                    <div className="title">
                                                        {currentBlock.bestPractices[0].title}
                                                    </div>
                                                )}
                                                <br />
                                                {currentBlock.bestPractices[0] && (
                                                    <div className="text">
                                                        {currentBlock.bestPractices[0].text}
                                                    </div>
                                                )}
                                                <br />
                                            </div>
                                        ) : (
                                            <div className="best-practice">
                                                <div className="text">
                                                    Complete all the questions in this block to see
                                                    how you compare to other companies in your
                                                    industry and to see a best practice story.
                                                </div>
                                            </div>
                                        )}
                                    </Collapse>

                                    <div className="mobile-only">
                                        <div className="divider" />
                                        <Button
                                            className="best-practice-action"
                                            onClick={this.toggleBestPractice}
                                        >
                                            <div className="text">
                                                {storyOpen ? 'Hide ' : 'Show '}
                                                Best Practice Story
                                            </div>
                                            {storyOpen ? (
                                                <FontAwesomeIcon
                                                    className="icon"
                                                    icon={faAngleUp}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    className="icon"
                                                    icon={faAngleDown}
                                                />
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </StyledContainer>
                ) : (
                    <WelcomeMsg
                        className={className}
                        title="Welcome!"
                        text="Answer questions in each block to receive your digital maturity score."
                    />
                )}
            </>
        )
    }
}

PreResultsContainer.defaultProps = {
    profile: {},
    blocks: [],
    currentBlock: {},
    select: () => {},
    goToResults: () => {},
}

PreResultsContainer.propTypes = {
    profile: PropTypes.object,
    blocks: PropTypes.array,
    currentBlock: PropTypes.object,
    select: PropTypes.func,
    goToResults: () => {},
}

export default PreResultsContainer
