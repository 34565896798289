import React, { useEffect } from 'react'

import { Block } from '../../Results/ResultsDefinition'

type Props = {
    block: Block
    onAreaClick: (block: Block) => void
}

function PreResultsBionicModal({ onAreaClick, block }: Props) {
    useEffect(() => {
        onAreaClick(block)
    }, [block.blockId])
    return null
}

export default PreResultsBionicModal
