import React, { Component } from 'react'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ResultsContainer from '../components/Results/ResultsContainer'
import BenchmarksContainer from '../components/Benchmarks/BenchmarksContainer'
import GoalsContainer from '../components/Goals/GoalsContainer'
import QuickBar from '../components/Results/QuickBar'
import { Page, PageLoad } from '../components/Page'
import { fetchSurvey, fetchSurveyFilters, filterSurvey } from '../api'
import routePaths from '../utils/routePaths'
import SimpleDialog from '../components/SimpleDialog'
import { StyledSpinner } from '../components/Styles'
import { FeedbackBar, FeedbackBarContentWrapper } from '../components/Feedback'
import { ResultsResponse } from '../components/Results/ResultsDefinition'

const StyledPage = styled.div`
    position: relative;
    background-color: ${props => props.theme.mainBackground};
    min-height: 100vh;
    transition: background 1s;
    .tab {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 16px;
        font-weight: bold;
        width: 50%;
        height: 40px;
    }

    &.bionic {
        background: #15202c;
        transition: background 1s;
    }

    @media only screen and (max-width: 768px) {
        .tab {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 320px) {
        .tab {
            font-size: 12px;
        }
    }
`

const styles = {
    tabs: {
        background: 'white',
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: 2,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
        fontFamily: 'Proxima Nova, sans-serif',
    },
}

type Props = {
    history: any
}

export type AppliedFilter = {
    label: string
    value: string
    type: string
}
export type FilterOption = {
    label: string
    value: string
}
export type FilterResponse = {
    active: boolean
    id: number
    option_source: string
    options: FilterOption[]
    placeholder: string
    type: string
}
export type ResultsWithFilters = ResultsResponse & {
    filterOptions: FilterResponse[]
    filters: AppliedFilter[]
}

type State = {
    surveyId?: number
    index: number
    results: ResultsWithFilters | null
    resultsBionic: ResultsWithFilters | null
    bionic: boolean
    appBar?: number
    loadingFilters?: boolean
    feedbackMessage?: {
        type: string
        text: string
    } | null
}

class ResultsPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            index: 0,
            results: null,
            resultsBionic: null,
            bionic: false,
        }
    }

    async componentDidMount() {
        const { history } = this.props
        if (!history.location.state || !history.location.state.surveyId) {
            history.push(routePaths.home)
            return
        }

        const { location } = history
        const { state } = location
        const { surveyId } = state

        const res = await fetchSurvey(surveyId)
        const resBionic = await fetchSurvey(surveyId, true)
        if (res.status !== 200 || resBionic.status !== 200) {
            history.push(routePaths.home)
            return
        }

        const filtersRes = await fetchSurveyFilters(surveyId)
        const results = res.data
        const resultsBionic = resBionic.data
        // for testing edge case of N/A block
        // results.blocks[0].preresults = { userScore: -100, descriptor: 'N/A' }
        results.filterOptions = resultsBionic.filterOptions = filtersRes.data
        results.filters = resultsBionic.filters = [
            {
                label: res.data.profile.industry,
                value: res.data.profile.industry,
                type: 'industry',
            },
        ]

        const appBar = document.getElementById('appBar')
        const height = appBar ? appBar.offsetHeight : 0
        this.setState({
            appBar: height,
            surveyId,
            results,
            resultsBionic,
        })
    }

    handleChange = (event: any, value: number) => {
        this.setState({
            index: value,
        })
    }

    handleChangeIndex = (index: number) => {
        this.setState({
            index,
        })
    }

    applyFilters = async (filters: any) => {
        // make a call to filter route
        // render results
        this.setState({
            loadingFilters: true,
        })
        const { surveyId, results, resultsBionic } = this.state
        const res = await filterSurvey(surveyId, filters)
        const resBionic = await filterSurvey(surveyId, filters, true)
        if (res.status !== 200 || resBionic.status !== 200) {
            this.setState({
                loadingFilters: false,
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error filtering your request',
                },
            })
        }
        const updatedResults = res.data
        const updatedResultsBionic = resBionic.data
        const filterOptions = results ? results.filterOptions : {}
        const filterOptionsBionic = resultsBionic ? resultsBionic.filterOptions : {}
        const newResults = Object.assign({}, updatedResults, { filters, filterOptions })
        const newResultsBionic = Object.assign({}, updatedResultsBionic, {
            filters,
            filterOptions: filterOptionsBionic,
        })
        this.setState({
            surveyId,
            results: newResults,
            resultsBionic: newResultsBionic,
            loadingFilters: false,
        })
    }

    resetFilters = async () => {
        // reset filters
        // render updated survey results
        this.setState({
            loadingFilters: true,
        })
        const { results, resultsBionic, surveyId } = this.state
        const profile = results ? results.profile : null
        const industry = profile ? profile.industry : null
        const baseFilters = [{ label: industry, value: industry }]
        const res = await filterSurvey(surveyId, baseFilters)
        const resBionic = await filterSurvey(surveyId, baseFilters, true)
        if (!res || res.status !== 200 || resBionic.status !== 200) {
            this.setState({
                loadingFilters: false,
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error filtering your request',
                },
            })
            return
        }

        let filterOptions = results ? results.filterOptions : []
        let filterOptionsBionic = resultsBionic ? resultsBionic.filterOptions : []
        filterOptions = filterOptions.map((o: any) => {
            return Object.assign(o, { answer: false })
        })
        filterOptionsBionic = filterOptionsBionic.map((o: any) => {
            return Object.assign(o, { answer: false })
        })
        res.data.filterOptions = filterOptions
        resBionic.data.filterOptions = filterOptionsBionic
        const newResults = Object.assign({}, res.data, { filters: baseFilters })
        const newResultsBionic = Object.assign({}, resBionic.data, { filters: baseFilters })
        this.setState({
            results: newResults,
            resultsBionic: newResultsBionic,
            loadingFilters: false,
        })
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const {
            index,
            appBar,
            results,
            resultsBionic,
            loadingFilters,
            feedbackMessage,
            bionic,
        } = this.state
        const setBionic = (bionic: boolean) => this.setState({ bionic })
        return (
            <Page {...this.props} appbar>
                {resultsBionic ? (
                    <StyledPage className={bionic ? 'bionic' : ''}>
                        {
                            // @ts-ignore
                            <Tabs
                                value={index}
                                variant="fullWidth"
                                onChange={this.handleChange}
                                style={{
                                    ...styles.tabs,
                                    ...{
                                        top: `${appBar}px`,
                                    },
                                }}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: '#5A5A5A',
                                    },
                                }}
                            >
                                <Tab
                                    label="Results"
                                    className="tab"
                                    style={{
                                        color: 'white',
                                        background: index === 0 ? '#485563' : '#57626b',
                                    }}
                                />
                                <Tab
                                    label="Benchmarks"
                                    className="tab"
                                    style={{
                                        color: 'white',
                                        backgroundColor: index === 1 ? '#485563' : '#57626b',
                                    }}
                                />
                                <Tab
                                    label="Goals"
                                    className="tab"
                                    style={{
                                        color: 'white',
                                        backgroundColor: index === 2 ? '#485563' : '#57626b',
                                    }}
                                />
                            </Tabs>
                        }

                        <SwipeableViews
                            index={index}
                            onChangeIndex={this.handleChangeIndex}
                            axis="x"
                            ignoreNativeScroll
                            resistance
                        >
                            <ResultsContainer
                                results={resultsBionic}
                                bionic={bionic}
                                setBionic={setBionic}
                            />
                            <BenchmarksContainer
                                results={resultsBionic}
                                applyFilters={this.applyFilters}
                                resetFilters={this.resetFilters}
                                currentIndex={index}
                                bionic={bionic}
                                setBionic={setBionic}
                            />
                            <GoalsContainer
                                results={resultsBionic}
                                applyFilters={this.applyFilters}
                                resetFilters={this.resetFilters}
                                currentIndex={index}
                                bionic={bionic}
                                setBionic={setBionic}
                            />
                        </SwipeableViews>
                    </StyledPage>
                ) : (
                    <PageLoad />
                )}

                <SimpleDialog dialogOpen={loadingFilters} closeDialog={() => {}} noClose>
                    <div style={{ textAlign: 'center' }}>
                        <div>Updating filters</div>
                        <br />
                        <StyledSpinner />
                    </div>
                </SimpleDialog>

                {feedbackMessage && (
                    <FeedbackBar open={!!feedbackMessage}>
                        <FeedbackBarContentWrapper
                            message={feedbackMessage.text}
                            variant={feedbackMessage.type}
                            onClose={this.closeFeedbackMessage}
                        />
                    </FeedbackBar>
                )}
            </Page>
        )
    }
}

export default ResultsPage
