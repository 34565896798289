import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import HistoryCardContainer from './HistoryCardContainer'

const StyledContainer = styled.div`
    width: 100%;
    right: 0;
    z-index: 1;
    max-width: 500px;
    margin: 15px auto;
    position: relative;

    .arrow-bar {
        position: absolute;
        width: 55px;
        top: 3px;
        bottom: 3px;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        .icon {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 40%;
            color: ${props => props.theme.chevron};
            line-height: 50px;
            font-size: 50px;
            text-align: center;
        }

        .icon:hover {
            color: white;
        }
    }

    .left {
        left: -60px;
    }

    .right {
        right: -60px;
    }

    .transparent {
        background-image: none;
    }

    .desktop-only {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        .desktop-only {
            display: inherit;
        }
    }
`

class HistoryCardsCarousel extends Component {
    state = {
        index: 0,
        styles: {
            root: {
                padding: window.innerWidth > 400 ? '0px 0px' : '0px 30px',
                overflowX: window.innerWidth > 400 ? 'hidden' : 'none',
                width: '100%',
                position: 'relative',
            },
            slideContainer: {
                padding: '5px 10px',
                overflow: 'none',
            },
            slide: {
                padding: 15,
                minHeight: 100,
                overflow: 'none',
            },
        },
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    handleChangeIndex = index => {
        this.setState({ index })
    }

    prev = () => {
        const { index } = this.state
        if (index > 0) {
            this.handleChangeIndex(index - 1)
        }
    }

    next = () => {
        const { index } = this.state
        const { surveys } = this.props
        if (index < surveys.length - 1) {
            this.handleChangeIndex(index + 1)
        }
    }

    resize = () => {
        this.setState({
            styles: {
                root: {
                    padding: window.innerWidth > 400 ? '0px 0px' : '0px 30px',
                    overflowX: window.innerWidth > 400 ? 'hidden' : 'none',
                    width: '100%',
                    position: 'relative',
                },
                slideContainer: {
                    padding: '5px 10px',
                    overflow: 'none',
                },
                slide: {
                    padding: 15,
                    minHeight: 100,
                    overflow: 'none',
                },
            },
        })
    }

    resetIndexAndUpdateHistory = deletedSurvey => {
        const { updateHistory } = this.props
        updateHistory(deletedSurvey)
        this.setState({
            index: 0,
        })
    }

    render() {
        const { index, styles } = this.state
        const { surveys, continueSurvey } = this.props
        return (
            <StyledContainer>
                <SwipeableViews
                    index={index}
                    enableMouseEvents
                    axis="x"
                    ignoreNativeScroll
                    onChangeIndex={this.handleChangeIndex}
                    style={styles.root}
                    slideStyle={styles.slideContainer}
                >
                    {surveys.map(s => (
                        <HistoryCardContainer
                            historyItem={s}
                            key={s.presurvey.presurveyId}
                            updateHistory={this.resetIndexAndUpdateHistory}
                            continueSurvey={continueSurvey}
                            {...this.props}
                        />
                    ))}
                </SwipeableViews>
                {index > 0 && surveys.length > 0 ? (
                    <div
                        className="arrow-bar left desktop-only"
                        onClick={this.prev}
                        role="button"
                        onKeyPress={() => {}}
                        tabIndex={0}
                    >
                        <div className="icon  transparent">
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </div>
                    </div>
                ) : (
                    <React.Fragment />
                )}
                {surveys.length < 2 || (surveys.length > 1 && index === surveys.length - 1) ? (
                    <React.Fragment />
                ) : (
                    <div
                        className="arrow-bar right desktop-only"
                        onClick={this.next}
                        role="button"
                        onKeyPress={() => {}}
                        tabIndex={-1}
                    >
                        <div className="icon  transparent">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                    </div>
                )}
            </StyledContainer>
        )
    }
}

HistoryCardsCarousel.defaultProps = {
    surveys: [],
    continueSurvey: () => {},
    updateHistory: () => {},
}

HistoryCardsCarousel.propTypes = {
    surveys: PropTypes.array,
    continueSurvey: PropTypes.func,
    updateHistory: PropTypes.func,
}

export default HistoryCardsCarousel
