import React, { ReactNode, ReactNodeArray } from 'react'
import styled from 'styled-components'

const StyledBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px ${props => props.theme.bionicBorder};
    background-image: ${props => props.theme.bionicBoxBackground};
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.17);
    color: ${props => props.theme.mainTextColor};
`

type Props = {
    className?: string
    children: string | number | ReactNode | ReactNodeArray
}

const BionicBox = ({ className, children }: Props) => {
    return <StyledBox className={className}>{children}</StyledBox>
}

export default BionicBox
