import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, ButtonBase } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { StyledButton } from '../Styles'
import PasswordReset from './PasswordReset'
import SimpleDialog from '../SimpleDialog'
import { validateEmailFormat, RECAPTCHA_THRESHOLD } from '../../utils'
import UserContext from '../../contexts/User'
import { Attribution } from '../GoogleRecaptcha'
import { FeedbackBarContentWrapper } from '../Feedback'
import { SimpleInput, PasswordInput } from '../FormElements'

const StyledContainer = styled.div`
    position: relative;

    .form-container {
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 100px;
        padding: 10px;

        .forgot-password {
            margin: 0 auto;
            font-family: 'Proxima Nova Alt', sans-serif;

            button {
                text-transform: none;
                font-size: 14px;
                text-decoration: underline;
                text-align: right;
                color: ${props => props.theme.mainTextColor};
                cursor: pointer;
                float: right;
                padding: 0;
            }
        }
    }

    .switch-views {
        max-width: 250px;
        margin: 10px auto;

        button {
            text-transform: none;
            font-size: 14px;
            text-decoration: underline;
            text-align: center;
            color: ${props => props.theme.mainTextColor};
            cursor: pointer;
            padding: 0;
            display: block;
            margin: 0 auto;
        }
    }

    .feedback {
        margin: 0 auto;
        max-width: 350px;
    }

    .recaptcha-attribution {
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
    }

    @media only screen and (min-width: 768px) {
        .form-container {
            max-width: 350px;
        }
    }
`

class SigninForm extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {
            password: '',
            email: '',
            passwordInputType: 'password',
            dialogOpen: false,
            feedbackMessage: null,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { captchaScore } = nextProps
        if (captchaScore < RECAPTCHA_THRESHOLD) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text:
                        'We have detected suspicious activity from this web address. Please refresh and try again or check your network setup.',
                },
            })
        }
    }

    handleInputChange = e => {
        const { name, value } = e.target
        this.setState({
            [name]: value,
            feedbackMessage: null,
        })
    }

    showPassword = () => {
        const { passwordInputType } = this.state
        const inputType = passwordInputType === 'password' ? 'text' : 'password'
        this.setState({
            passwordInputType: inputType,
        })
    }

    signin = async e => {
        e.preventDefault()
        const { email, password } = this.state
        const { captchaScore } = this.props
        if (captchaScore < RECAPTCHA_THRESHOLD) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text:
                        'We have detected suspicious activity from this web address. Please refresh and try again or check your network setup.',
                },
            })
            return
        }
        if (!validateEmailFormat(email)) {
            this.setState({
                feedbackMessage: { type: 'error', text: 'Please enter a valid email' },
            })
            return
        }
        if (password.length < 1) {
            this.setState({ feedbackMessage: { type: 'error', text: 'Please enter a password' } })
            return
        }
        const { login } = this.context
        const res = await login(email, password)
        if (res.status !== 200) {
            this.setState({ feedbackMessage: { type: 'error', text: res.data.message } })
        } else {
            const { signin } = this.props
            signin()
        }
    }

    toggleDialog = () => {
        const { dialogOpen } = this.state
        this.setState({
            dialogOpen: !dialogOpen,
            feedbackMessage: null,
            email: '',
            password: '',
        })
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const { email, feedbackMessage, dialogOpen, password } = this.state

        const { requestAccount, captchaScore } = this.props
        return (
            <StyledContainer className="bionic">
                <form className="form-container" onSubmit={this.signin}>
                    <SimpleInput
                        handleInputChange={this.handleInputChange}
                        value={email}
                        name="email"
                        placeholder="Enter your email"
                    >
                        <FontAwesomeIcon icon={faUser} />
                    </SimpleInput>
                    <PasswordInput handleInputChange={this.handleInputChange} value={password} />

                    <div className="forgot-password">
                        <ButtonBase onClick={this.toggleDialog}>Forgot Password?</ButtonBase>
                    </div>
                    <br />

                    {feedbackMessage ? (
                        <div className="feedback">
                            <br />
                            <FeedbackBarContentWrapper
                                message={feedbackMessage.text}
                                variant={feedbackMessage.type}
                                onClose={this.closeFeedbackMessage}
                            />
                        </div>
                    ) : null}
                    <br />

                    <StyledButton
                        disabled={captchaScore < RECAPTCHA_THRESHOLD}
                        type="submit"
                        onClick={this.signIn}
                        customClass={`narrow medium center ${
                            captchaScore < RECAPTCHA_THRESHOLD ? 'disabled' : ''
                        }`}
                    >
                        Login
                    </StyledButton>

                    <div className="switch-views">
                        <Button onClick={requestAccount}>Request an account</Button>
                    </div>
                </form>

                <div className="recaptcha-attribution">
                    <Attribution />
                </div>
                <br />

                <SimpleDialog dialogOpen={dialogOpen} closeDialog={this.toggleDialog}>
                    <PasswordReset
                        closeDialog={this.toggleDialog}
                        resetPassword={this.resetPassword}
                    />
                </SimpleDialog>
            </StyledContainer>
        )
    }
}

SigninForm.defaultProps = {
    signin: () => {},
    requestAccount: () => {},
    captchaScore: 1,
}

SigninForm.propTypes = {
    signin: PropTypes.func,
    requestAccount: PropTypes.func,
    captchaScore: PropTypes.number,
}

export default SigninForm
