import React, { Component } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons'

const StyledBlock = styled.div`
    position: relative;
    z-index: 2;
    max-width: 800px;
    margin: 0 auto;
    padding: 5px 5px 15px 5px;
    border-radius: 3px;
    box-shadow: 0 0 4px ${props => props.theme.black};
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    background: ${props => props.theme.darkblueGradient};
    color: ${props => props.theme.mainTextColor};

    .section-title {
        margin-top: 5px;
        font-size: 1.2rem;
        padding: 5px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .section-description {
        margin-top: 15px;
        font-size: 16px;
        padding: 5px;
        max-width: 450px;
    }

    .section-icon {
        float: right;
        width: 50px;
        height: 50px;
        margin: 5px 5px;
        border-radius: 100%;
        font-size: 1.5rem;
        color: ${props => props.theme.mainTextColor};
        line-height: 45px;
        text-align: center;
        cursor: pointer;
    }

    @keyframes animateOpacity {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @media only screen and (min-width: 768px) {
        .section-title {
            margin-top: 15px;
        }

        .section-icon {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            font-size: 55px;
            text-align: center;
            line-height: 85px;
            border-width: 5px !important;
            color: gold;
            float: right;
            margin: 15px 15px;
            cursor: pointer;
        }
    }

    .bionic & {
        border-radius: 4px;
        box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.17);
        border: solid 1px #1d5aaa;
        background-image: radial-gradient(circle at 50% 0, #173458, #142331 123%);
        padding: 24px;

        .section-title {
            padding: 0;
            font-size: 17px;
            font-weight: bold;
        }
        .section-description {
            padding: 0;
            line-height: 1.2;
            margin: 16px 0;
            font-size: 17px;
            font-weight: 300;
        }

        @media only screen and (max-width: 768px) {
            .section-title {
                font-size: 13px;
            }
            .section-description {
                font-size: 13px;
            }
        }
    }
`

type Props = {
    bionic: boolean
}

type State = {
    scrollPosition: number
}

class GoalTopBanner extends Component<Props, State> {
    state = {
        scrollPosition: 0,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scroll)
    }

    scroll = () => {
        const doc = document.documentElement
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        const { scrollPosition } = this.state
        if (scrollPosition > 200 && top > 200) {
            // don't change state
        } else if (scrollPosition < 200 && top > 200) {
            this.setState({ scrollPosition: 201 })
        } else if (scrollPosition > 200 && top < 201) {
            this.setState({ scrollPosition: 0 })
        }
    }

    render() {
        const { scrollPosition } = this.state
        const { bionic } = this.props
        return (
            <StyledBlock style={{ opacity: scrollPosition > 0 ? 0 : 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={bionic ? 12 : 9} sm={9}>
                        <div className="section-title">IMPORTANCE & AMBITION</div>
                        <div className="section-description">
                            Explore the topics that you ranked as most important and consider key
                            factors that will enable you to achieve your digital maturity goals.
                        </div>
                    </Grid>
                    {!bionic && (
                        <Grid item xs={3}>
                            <div className="section-icon">
                                <FontAwesomeIcon className="icon" icon={faStar} />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </StyledBlock>
        )
    }
}

export default GoalTopBanner
