import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PreResultsContainer from './PreResults/PreResultsContainer'
import PreResultsBionicWheel from './PreResultsBionicWheel/PreResultsBionicWheel'
import { scrollTo } from '../../utils'

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    padding-top: 65px;
    padding-bottom: 100px;
    margin: 0 auto;

    .block-padding {
        padding: 0 10px 0 10px;
        margin-bottom: -15px;
    }

    .pre-results-block {
        margin-bottom: 90px;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 45px;
        padding-bottom: 45px;

        @media only screen and (max-width: 768px) {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
`

class BlocksList extends Component {
    componentDidMount() {
        scrollTo(0, 0)
    }

    render() {
        const {
            profile,
            blocks,
            select,
            currentBlock,
            updateCurrentBlock,
            goToResults,
        } = this.props

        return (
            <StyledContainer>
                <PreResultsContainer
                    className="pre-results-block"
                    profile={profile}
                    blocks={blocks}
                    currentBlock={currentBlock}
                    updateCurrentBlock={updateCurrentBlock}
                    select={select}
                    goToResults={goToResults}
                />

                <PreResultsBionicWheel results={{ blocks }} onAreaClick={select} />
            </StyledContainer>
        )
    }
}

BlocksList.defaultProps = {
    profile: {},
    blocks: [],
    select: () => {},
    currentBlock: {},
    updateCurrentBlock: () => {},
    goToResults: () => {},
}

BlocksList.propTypes = {
    profile: PropTypes.object,
    blocks: PropTypes.array,
    select: PropTypes.func,
    currentBlock: PropTypes.object,
    updateCurrentBlock: PropTypes.func,
    goToResults: PropTypes.func,
}

export default BlocksList
