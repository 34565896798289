import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import rightArrow from '../../../assets/customIcons/arrow-right.svg'
import pausePlay from '../../../assets/customIcons/pause.svg'
import ProgressContainer from './ProgressContainer'

const StyledBlock = styled.div`
    border-radius: 3px;

    .block {
        position: relative;
        z-index: 2;
        min-height: 140px;
        max-width: 780px;
        margin: 1rem auto;
        padding: 5px;
        border-radius: 3px;
        box-shadow: ${props => props.theme.shadowGrey};
        color: ${props => props.theme.mainTextColor};
        text-transform: uppercase;
        -webkit-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;

        .block-top-title {
            font-size: 0.7rem;
            padding: 5px;
        }

        .block-title {
            font-size: 1.2rem;
            padding: 5px;
            font-weight: bold;
        }

        button {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
        }

        .block-img-btn {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            float: right;
            width: 50px;
            position: relative;
            height: 50px;
        }

        .block-pause-btn {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            float: right;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 15px;
            right: 15px;
        }

        .block-stats {
            position: absolute;
            bottom: 10px;
            right: 10px;
            left: 10px;
        }

        .block-time {
            font-size: 0.8rem;
            text-align: center;
            position: absolute;
            bottom: -20px;
            left: 0;
            right: 0;
        }
    }

    @media only screen and (min-width: 660px) {
        .responsive-block {
            .block {
                min-height: 230px;
                text-align: center;

                .block-img-btn {
                    position: absolute;
                    bottom: 10px;
                    background: none;
                    color: inherit;
                    border: none;
                    padding: 0;
                    font: inherit;
                    cursor: pointer;
                    outline: inherit;
                    display: block;
                    margin: 20px auto;
                    margin-bottom: 60px;
                    width: 100%;
                    height: 50px;
                    left: 0;
                    right: 0;
                }

                .block-stats {
                    max-width: 120px;
                    margin: 0 auto;
                    min-height: 20px;
                }

                .center-text {
                    text-align: center;
                }

                .left-text {
                    text-align: left;
                }

                .block-time {
                    // position: relative;
                }
            }
        }
    }
`

class Block extends Component {
    state = {
        blockLoaded: 0,
    }

    componentDidMount() {
        this.setState({
            blockLoaded: 1,
        })
    }

    select = () => {
        const { select, block } = this.props
        select(block)
    }

    render() {
        const { block, time, progressindex, listblock, toggleBlockList } = this.props
        const { blockLoaded } = this.state
        const { properties } = block

        return (
            <StyledBlock
                onClick={this.select}
                style={{ backgroundColor: properties.color, opacity: blockLoaded }}
            >
                <div className={listblock ? 'responsive-block' : ''}>
                    <div className="block">
                        <Grid container spacing={0}>
                            <Grid item xs={9} sm={12}>
                                <div className="block-title">{block.title}</div>
                            </Grid>

                            <Grid item xs={3} sm={12}>
                                {listblock ? (
                                    <Button
                                        type="button"
                                        className="block-img-btn"
                                        onClick={toggleBlockList}
                                    >
                                        <img
                                            alt="right-arrow"
                                            src={rightArrow}
                                            width="50"
                                            height="50"
                                        />
                                        {time ? (
                                            <div className="block-time">
                                                {`${block.questions.length} min`}
                                            </div>
                                        ) : null}
                                    </Button>
                                ) : (
                                    <Button
                                        type="button"
                                        className="block-pause-btn"
                                        onClick={toggleBlockList}
                                    >
                                        <img
                                            alt="pause-play"
                                            src={pausePlay}
                                            width="50"
                                            height="50"
                                        />
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                        <div
                            className={`block-stats ${
                                block.questions.length < 2 ? 'center-text' : 'left-text'
                            }`}
                        >
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <ProgressContainer
                                        block={block}
                                        progressindex={progressindex}
                                        listblock={listblock}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </StyledBlock>
        )
    }
}

Block.defaultProps = {
    block: {},
    select: () => {},
    time: false,
    progressindex: 0,
    listblock: true,
    toggleBlockList: () => {},
}

Block.propTypes = {
    block: PropTypes.object,
    select: PropTypes.func,
    time: PropTypes.bool,
    progressindex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    listblock: PropTypes.bool,
    toggleBlockList: PropTypes.func,
}

export default Block
