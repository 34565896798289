import React, { useContext } from 'react'
import styled from 'styled-components'

import UserContext from '../contexts/User'

import { Page } from '../components/Page'
import { OnboardingList } from '../components/Onboarding'
import BuildInfo from '../components/BuildInfo'
import { StyledButton } from '../components/Styles'
import AppBar from '../components/AppBar'
import DigitalMaturity from '../components/DigitalMaturity/DigitalMaturity.tsx'
import SimpleBionicWheel from '../components/SimpleBionicWheel/SimpleBionicWheel.tsx'

const StyledPage = styled.div`
    position: relative;
    height: 100%;
    min-height: 100vh;
    padding: 20px 20px 0 20px;
    background-color: ${props => props.theme.mainBackground};
    color: ${props => props.theme.mainTextColor};

    .login {
        float: right;
    }

    .page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 940px;
        margin: 0 auto;
        padding-top: 100px;
    }

    .section-title {
        margin-bottom: 15px;
        font-size: 44px;
        font-weight: normal;
        text-align: center;
    }

    .section-subtitle {
        max-width: 610px;
        margin-bottom: 60px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
    }

    .section-title-body {
        margin-bottom: 25px;
        font-size: 24px;
        font-weight: normal;
        text-align: center;
    }
`

const AboutPage = props => {
    const user = useContext(UserContext)
    return (
        <Page {...props} background appbar={false}>
            {user.isAuthenticated && <AppBar {...props} />}
            <StyledPage>
                {!user.isAuthenticated && (
                    <div className="login">
                        <StyledButton
                            onClick={() => {
                                props.history.push('/')
                            }}
                            customClass="short narrow"
                        >
                            Login
                        </StyledButton>
                    </div>
                )}

                <div className="page-content">
                    <h1 className="section-title">About DAI</h1>
                    <p className="section-subtitle">
                        Tectonic shifts are changing the way businesses are run. Technology is
                        accelerating at a dramatic pace, people are changing their ways of working,
                        and customer demands are shifting. Research shows that companies that
                        embrace these changes and properly balance and integrate the roles of
                        technology and humans to unleash the maximum potential of both,
                        significantly outperform the laggards. These companies are what we call
                        Bionic companies, blending human and technological capabilities to achieve
                        superior outcomes. The Bionic Acceleration Index (BAI) helps Organizations
                        assess their Bionic maturity. It reveals strengths and weaknesses,
                        determines whether human and technology capabilities are lacking or
                        imbalanced and evaluates how well they perform against peers.
                    </p>

                    <SimpleBionicWheel />
                    <DigitalMaturity />
                    <OnboardingList />
                </div>
                <BuildInfo />
            </StyledPage>
        </Page>
    )
}

export default AboutPage
