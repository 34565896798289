import React from 'react'
import styled from 'styled-components'
import { Element } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'

import COPY from '../../references/copy.json'

const StyledIntro = styled.div`
    padding-top: 100px;
    padding-bottom: 95px;

    .intro-section {
        height: 100%;
    }

    .intro-title {
        position: relative;
        top: 30%;
        text-align: center;
        color: ${props => props.theme.mainTextColor};
    }

    .title {
        margin-bottom: 10px;
        font-size: 44px;
        font-weight: normal;
    }

    .subtitle {
        font-size: 18px;
    }
`

const PreSurveyTitle = () => {
    return (
        <StyledIntro>
            <Element className="intro-section" key="intro-element">
                <QueueAnim className="intro-title" type="left">
                    <h2 className="title">{COPY.presurvey.intro}</h2>
                    <p className="subtitle">{COPY.presurvey.description}</p>
                </QueueAnim>
            </Element>
        </StyledIntro>
    )
}

export default PreSurveyTitle
