import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import FilterQuestion from './FilterQuestion'
import { StyledButton } from '../Styles'

const StyledContainer = styled.div`
    position: relative;
    overflow-y: hidden;
    height: 100%;

    .filter-questions {
        padding: 10px;
        overflow: auto;
        padding-bottom: 100px;
        .bionic & {
            padding: 24px;
        }
    }

    .filter-options {
        padding: 25px 10px 10px 10px;
        height: 80px;
        width: 100%;
        box-shadow: 0px -2px 4px ${props => props.theme.darkblue};
        .bionic & {
            box-shadow: none;
            padding: 24px;
            height: auto;
        }
    }
`

class FilterQuestions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: null,
            filters: this.props.results.filters.map(f => {
                return Object.assign({}, f)
            }),
        }
    }

    componentDidMount() {
        // get filter questions
        // attach responses to filter questions from props filters
        const { results } = this.props
        const { filterOptions } = results
        this.setState({
            questions: filterOptions,
        })
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            filters: newProps.results.filters,
        })
    }

    selected = option => {
        const { filters, questions } = this.state
        const [selectedValueExistsInFilters] = filters.filter(f => f.value === option.value)
        const [currentQuestion] = questions.filter(q => q.type === option.type)
        let newFilters = filters.filter(f => f.type !== option.type)
        if (selectedValueExistsInFilters) {
            currentQuestion.answer = []
        } else {
            currentQuestion.answer = [option]
            newFilters.push(option)
        }
        this.setState({
            filters: newFilters,
            questions,
        })
    }

    applyFilters = () => {
        const { applyFilters } = this.props
        const { filters } = this.state
        applyFilters(filters)
    }

    render() {
        const { questions } = this.state
        const { toggleDrawer, resetFilters } = this.props
        return (
            <StyledContainer>
                <div className="filter-options">
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <StyledButton
                                onClick={this.applyFilters}
                                customClass="center narrow bionic-dark"
                            >
                                Apply
                            </StyledButton>
                        </Grid>
                        <Grid item xs={4}>
                            <StyledButton
                                onClick={resetFilters}
                                customClass="center narrow bionic-dark"
                            >
                                Reset
                            </StyledButton>
                        </Grid>
                        <Grid item xs={4}>
                            <StyledButton
                                onClick={toggleDrawer}
                                customClass="center narrow bionic-dark"
                            >
                                Cancel
                            </StyledButton>
                        </Grid>
                    </Grid>
                </div>
                <div
                    className="filter-questions"
                    style={{ height: `${window.innerHeight - 70}px` }}
                >
                    {questions ? (
                        questions.map(q => (
                            <FilterQuestion key={q.id} question={q} selected={this.selected} />
                        ))
                    ) : (
                        <div>filters</div>
                    )}
                </div>
            </StyledContainer>
        )
    }
}

FilterQuestions.defaultProps = {
    filters: [],
    applyFilters: () => {},
    toggleDrawer: () => {},
    resetFilters: () => {},
    results: {},
}

FilterQuestions.propTypes = {
    filters: PropTypes.array,
    applyFilters: PropTypes.func,
    toggleDrawer: PropTypes.func,
    resetFilters: PropTypes.func,
    results: PropTypes.object,
}

export default FilterQuestions
