import React from 'react'
import Grid from '@material-ui/core/Grid'

import SingleSelectOption from './SingleSelectOption'

type Props = {
    selected: Function
    question: Record<string, any>
    short: boolean
}

const SingleSelect = ({ selected = () => {}, question = {}, short = false }: Props) => {
    const onSelected = (option: any) => {
        selected([option])
    }

    return (
        <Grid container spacing={0}>
            {question.set_options.map((it: Record<string, any>) => (
                <Grid
                    key={it.label}
                    item
                    xs={12}
                    sm={short ? 6 : 12}
                    md={short ? 6 : 12}
                    lg={short ? 6 : 12}
                >
                    <SingleSelectOption option={it} question={question} select={onSelected} />
                </Grid>
            ))}
        </Grid>
    )
}

export default SingleSelect
