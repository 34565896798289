import React from 'react'
import styled from 'styled-components'

import packageContents from '../../../package.json'
import buildInfo from '../../REACT_APP_BUILD_INFO'

const StyledInfo = styled.section`
    margin-bottom: 25px;
    font-size: 12px;
    color: ${props => props.theme.mainTextColor};
    text-align: center;
`

const dirtyBuildMarker = buildInfo.isWorkingDirectoryDirty ? '*' : ''
const appVersion = packageContents.version
const versionInfo = `${appVersion} / ${buildInfo.revisionId}${dirtyBuildMarker}`

function BuildInfo() {
    return (
        <StyledInfo>
            <span>Version: {versionInfo}</span>
            <br />
            <span>Built on: {buildInfo.buildTime}</span>
        </StyledInfo>
    )
}

export default BuildInfo
