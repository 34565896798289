import React from 'react'
import styled from 'styled-components'

import BionicWheel from '../../Bionic/BionicWheel'
import PreResultsBionicModal from './PreResultsBionicModal'
import PreResultsIndicators from './PreResultsIndicators'
import { Block } from '../../Results/ResultsDefinition'

const StyledContainer = styled.section`
    margin-bottom: 140px;
`

type Props = {
    results: {
        blocks: Block[]
    }
    onAreaClick: () => void
}

const PreResultsBionicWheel = ({ results, onAreaClick }: Props) => {
    return (
        <StyledContainer>
            <BionicWheel
                // @ts-ignore
                results={results}
                renderScore={(block: Block) => <PreResultsIndicators block={block} />}
                renderModal={block => (
                    <PreResultsBionicModal block={block} onAreaClick={onAreaClick} />
                )}
            />
        </StyledContainer>
    )
}

export default PreResultsBionicWheel
