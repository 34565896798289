import React from 'react'
import { Redirect } from 'react-router-dom'

import { routePaths } from '../utils'

const NotFoundPage = () => {
    return <Redirect to={{ pathname: routePaths.home, state: 'none' }} />
}

export default NotFoundPage
