import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PreSurveyTitle from './PreSurveyTitle.tsx'
import PreSurveySection from './PreSurveySection'
import PreSurveyFinish from './PreSurveyFinish'
import PreSurveyStatusBar from './PreSurveyStatusBar'
import { scrollTo } from '../../utils'

const StyledPreSurveyContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
`

class PreSurveyContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showStatusProgress: true,
            appBarHeight: 30,
            currentIndex: this.props.currentIndex,
            containerHeight: window.innerHeight - 30 * 2 - 20 + 'px',
        }
        this.introRef = React.createRef()
        this.sectionRefs = [null]
        this.finRef = React.createRef()
        this.scrollerRef = React.createRef()
        this.scrollerTimer = -1
        this.scrollBuffer = 70
        this.scrollSpeed = 300
        this.scrollClock = 200
    }

    componentDidMount() {
        const appBar = document.getElementById('appBar')
        const height = appBar.offsetHeight
        scrollTo(0, 0)
        window.addEventListener('scroll', this.listenScrollEvent, { passive: false })
        window.addEventListener('resize', this.resize, { passive: true })
        this.setState({
            appBarHeight: height,
        })
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(
            {
                currentIndex: newProps.currentIndex,
            },
            () => {
                const { questions } = newProps
                const nextIndex =
                    newProps.currentIndex === questions.length ? -1 : newProps.currentIndex + 1
                this.updateCurrentIndex(nextIndex)
            }
        )
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenScrollEvent, { passive: false })
        window.removeEventListener('resize', this.resize, { passive: false })
        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer)
        }
    }

    resize = () => {}

    isMobileDevice = () => {
        return (
            typeof window.orientation !== 'undefined' ||
            navigator.userAgent.indexOf('IEMobile') !== -1
        )
    }

    listenScrollEvent = e => {
        const { scrolling } = this.state
        if (!scrolling) {
            this.setState({ scrolling: true })
        }
        // clear out the timer to prevent multiple fires on scroll end
        clearTimeout(this.timer)
        if (this.timer !== -1) {
            clearTimeout(this.timer)
        }
        this.timer = this.scrollTimer(e)
    }

    scrollTimer = e =>
        setTimeout(() => {
            this.scrollEnded(e)
        }, this.scrollClock)

    scrollEnded = e => {
        // clearTimeout(this.scrollTimer);
        // prevent this from running if the scroll event is coming from an internal element
        if (e && !e.target) {
            return
        }

        // find the section nearest the latest scrollTop value
        const elements = this.sectionRefs.slice(1).map(r => {
            const index = parseInt(r.getAttribute('data-index'), 10)
            return {
                index,
                y: r.offsetTop,
            }
        })
        // elements = elements.filter(el => el != null);
        elements.push({ index: 0, y: this.introRef.current.offsetTop })
        elements.push({ index: -1, y: this.finRef.current.offsetTop })

        const doc = document.documentElement
        const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        const closest = this.closestY(scrollTop, elements)

        // set the nearest section's index as current index if it's not aleady that index
        const { currentIndex } = this.state
        if (currentIndex !== closest.index) {
            this.setState({ currentIndex: closest.index, scrolling: false })
        } else {
            this.setState({ scrolling: false })
        }
    }

    closestY = (y, arr) => {
        let [curr] = arr
        let diff = Math.abs(y - curr.y)
        for (let val = 0; val < arr.length; val += 1) {
            const newdiff = Math.abs(y - arr[val].y)
            if (newdiff < diff) {
                diff = newdiff
                curr = arr[val]
            }
        }
        return curr
    }

    updateCurrentIndex = index => {
        let nextRef = null
        if (!index || index === 0) {
            nextRef = this.introRef.current
        } else if (index === -1) {
            nextRef = this.finRef.current
        } else {
            nextRef = this.sectionRefs[index]
        }
        // scroll to correct next section
        scrollTo(nextRef.offsetTop - this.scrollBuffer, this.scrollSpeed)
    }

    progressState = showStatusProgress => {
        const isMobile = this.isMobileDevice()
        if (!isMobile) {
            return
        }
        this.setState({ showStatusProgress })
    }

    finish = () => {
        const { finish } = this.props
        finish()
    }

    render() {
        const { currentIndex, showStatusProgress, appBarHeight, scrolling } = this.state
        const { updateQuestion, progress, updateProgress, questions, searchCompany } = this.props

        if (!questions) {
            return null
        }

        return (
            <>
                <StyledPreSurveyContainer ref={this.scrollerRef}>
                    <div data-id="0" ref={this.introRef}>
                        <PreSurveyTitle key="intro" nextQuestionId={questions[0].id} />
                    </div>

                    {questions.map((question, ii) => (
                        <div
                            ref={ref => {
                                this.sectionRefs[ii + 1] = ref
                            }}
                            key={question.id}
                            data-index={ii + 1}
                        >
                            <PreSurveySection
                                id={question.id}
                                index={ii + 1}
                                type={question.type}
                                question={question}
                                nextQuestionId={questions[ii + 1] ? questions[ii + 1].id : -1}
                                updateQuestion={updateQuestion}
                                next={this.next}
                                currentIndex={currentIndex}
                                updateIndex={this.updateCurrentIndex}
                                progressState={this.progressState}
                                searchCompany={searchCompany}
                            />
                        </div>
                    ))}
                    <div data-id="-1" ref={this.finRef}>
                        <PreSurveyFinish
                            key="finish"
                            questions={questions}
                            updateIndex={this.updateCurrentIndex}
                            finish={this.finish}
                        />
                    </div>
                </StyledPreSurveyContainer>

                {showStatusProgress ? (
                    <PreSurveyStatusBar
                        style={{
                            position: 'fixed',
                            bottom: '0',
                            left: '0',
                            right: '0',
                        }}
                        height={appBarHeight + 15}
                        navbarAction={this.nav}
                        currentIndex={currentIndex}
                        questions={questions}
                        updateIndex={this.updateCurrentIndex}
                        progress={progress}
                        updateProgress={updateProgress}
                        scrolling={scrolling}
                        finish={this.finish}
                    />
                ) : null}
            </>
        )
    }
}

PreSurveyContainer.defaultProps = {
    updateQuestion: () => {},
    progress: 0,
    updateProgress: () => {},
    finish: () => {},
    questions: [],
    searchCompany: () => {},
}

PreSurveyContainer.propTypes = {
    updateQuestion: PropTypes.func,
    progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    updateProgress: PropTypes.func,
    finish: PropTypes.func,
    questions: PropTypes.array,
    searchCompany: PropTypes.func,
}

export default PreSurveyContainer
