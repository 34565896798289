import { getApi, postApi, deleteApi, BASE_URI, assertAuthentication } from '../utils'

const fetchSurvey = async (surveyId, bionic) => {
    const res = await getApi(`${BASE_URI}/survey/${surveyId}?mode=Bionic`)
    return assertAuthentication(res)
}

const createSurvey = async (presurveyId, userId) => {
    const res = await postApi(`${BASE_URI}/survey`, { presurveyId, userId })
    return assertAuthentication(res)
}

const submitSurveyAnswer = async (surveyId, questionId, answer) => {
    const res = await postApi(
        `${BASE_URI}/survey/${surveyId}/question/${questionId}/answer`,
        answer
    )
    return assertAuthentication(res)
}

const deleteSurveyAnswer = async (surveyId, questionId) => {
    const res = await deleteApi(`${BASE_URI}/survey/${surveyId}/question/${questionId}/answer`)
    return assertAuthentication(res)
}

const fetchSurveyFilters = async surveyId => {
    const res = await getApi(`${BASE_URI}/survey/${surveyId}/filters`)
    return assertAuthentication(res)
}

const filterSurvey = async (surveyId, filters, bionic) => {
    let filterQuery = '?'
    for (let i = 0; i < filters.length; i++) {
        filterQuery += filters[i].type + '=' + filters[i].value + '&'
    }
    if (bionic) {
        filterQuery += 'mode=Bionic'
    }
    const res = await getApi(`${BASE_URI}/survey/${surveyId}${filterQuery}`)
    return assertAuthentication(res)
}

export {
    fetchSurvey,
    createSurvey,
    submitSurveyAnswer,
    deleteSurveyAnswer,
    fetchSurveyFilters,
    filterSurvey,
}
