import React from 'react'
import { Block } from '../Results/ResultsDefinition'
import { Modal, WithStyles, withStyles } from '@material-ui/core'
import iconClose from '../../assets/bionic/icons/icon_close.png'
import styled from 'styled-components'

type Props = {
    block: Block
    onClose: () => void
}

const StyledContainer = styled.div`
    border-radius: 4px;
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #146fe7;
    background-image: linear-gradient(to bottom, #142331 0%, #173458 330%);
    padding: 32px 48px;
    color: #fff;
    width: 480px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: 0;
    max-width: calc(100% - 24px);
    max-height: calc(100% - 24px);
    overflow: auto;

    @media only screen and (max-width: 768px) {
        padding: 24px 36px;
    }
`

const modalStyles = {
    title: {
        fontSize: 22,
        marginBottom: 32,
        fontFamily: 'Proxima Nova',
        '@media only screen and (max-width: 768px)': {
            fontSize: 16,
        },
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#7396a4',
        marginBottom: 8,
        fontFamily: 'Proxima Nova',
        '@media only screen and (max-width: 768px)': {
            fontSize: 12,
            marginBottom: 4,
        },
    },
    closeButton: {
        position: 'absolute',
        top: 24,
        right: 24,
        width: 24,
        cursor: 'pointer',
        '@media only screen and (max-width: 768px)': {
            top: 12,
            right: 12,
        },
    },
}

function BionicModal({
    block,
    onClose,
    children,
    classes,
}: React.PropsWithChildren<Props & WithStyles>) {
    const area = block.blockThemeTitle
    return (
        <Modal open onClose={onClose}>
            <StyledContainer className="bionic">
                <img
                    src={iconClose}
                    alt="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                />
                {!!area && area !== block.title && (
                    <div className={classes.subtitle}>{area.toUpperCase()}</div>
                )}
                <div className={classes.title}>{block.title}</div>
                {children}
            </StyledContainer>
        </Modal>
    )
}

// @ts-ignore
export default withStyles(modalStyles)(BionicModal)
