import React, { useEffect } from 'react'
import createHistory from 'history/createBrowserHistory'
import createHashHistory from 'history/createHashHistory'

import AppProvider from './contexts/App'
import AppRouter from './router'
import { GoogleSiteKey, getBrowser } from './utils'
import { GlobalStyles } from './components/Styles'
import { loadReCaptcha } from './components/GoogleRecaptcha'

// check browser compatibility
// ie < 10 takes issue with browser history push method so replace with hash
// ie < 10 takes issue with certain methods and rendering so it is not compatible
// ie < 10 will result in an incompatibility message on landing page
const browser = getBrowser()
let browserCompatible = true
let history
if (browser.name === 'MSIE' && parseInt(browser.version, 10) < 10) {
    history = createHashHistory()
    browserCompatible = false
} else {
    history = createHistory()
}

// load global styles
// initiate routes
const App = () => {
    useEffect(() => {
        if (browserCompatible) {
            loadReCaptcha(GoogleSiteKey)
            history.listen(location => {
                window.dispatchEvent(new CustomEvent('routechange', { detail: location }))
            })
        }
    }, [])

    return (
        <div>
            <GlobalStyles />
            <AppProvider>
                <AppRouter history={history} />
            </AppProvider>
        </div>
    )
}

export default App
