import React from 'react'
import styled from 'styled-components'

import BionicBox from '../../BionicBox/BionicBox'

const StyledBionicBox = styled(BionicBox)`
    position: relative;
    z-index: 2;
    flex-direction: column;
    min-height: 100px;
    margin: 10px auto;
    padding: 15px;
    border-radius: 5px;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;

    .title {
        display: block;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 24px;
    }

    .text {
        margin-top: 5px;
    }
`

type Props = {
    title: string
    text: string
    className: string
}

const WelcomeMsg = ({ title = '', text = '', className = '' }: Props) => {
    return (
        <StyledBionicBox className={className}>
            <span className="title">{title}</span>
            <p className="text">{text}</p>
        </StyledBionicBox>
    )
}

export default WelcomeMsg
