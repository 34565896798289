import React from 'react'
import styled from 'styled-components'

const ItemArea = styled.div`
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;

    .score {
        display: block;
        width: 40px;
        height: 28px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    .dash {
        width: 40px;
        height: 6px;
        background-color: ${props => props.theme.dashBackground};
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }
`

type Props = {
    block: Record<string, any>
    currentBlock: Record<string, any>
    updateCurrentBlock: Function
    index: number
}

const PreResultItem = ({
    block = {},
    currentBlock = {},
    updateCurrentBlock = () => {},
    index = 0,
}: Props) => {
    const { preresults } = block
    const score = preresults?.userScore >= 0 ? preresults.userScore.toString() : ''

    const onUpdateCurrentBlock = () => {
        updateCurrentBlock(block, index)
    }

    return (
        <ItemArea>
            <span className="score">{score ? score : ''}</span>
            <div
                className="dash"
                style={{
                    backgroundColor: currentBlock.blockId === block.blockId ? '#1d5aaa' : '',
                }}
                onClick={onUpdateCurrentBlock}
            />
        </ItemArea>
    )
}

export default PreResultItem
