import React from 'react'
import styled from 'styled-components'

import data from './data'

import BionicWheel from '../Bionic/BionicWheel'
import SimpleBionicModal from './SimpleBionicModal'

const StyledContainer = styled.section`
    margin-bottom: 140px;

    .title {
        margin-bottom: 60px;
        font-size: 24px;
        font-weight: normal;
        text-align: center;
    }

    .text {
        font-size: 12px;
        text-decoration: underline;
    }
`

const SimpleBionicWheel = () => {
    return (
        <StyledContainer>
            <h2 className="title">DAI's main building blocks</h2>

            <BionicWheel
                // @ts-ignore
                results={data}
                renderScore={() => <div className="text">Read more</div>}
                renderModal={(block, onClose) => (
                    <SimpleBionicModal block={block} onClose={onClose} />
                )}
            />
        </StyledContainer>
    )
}

export default SimpleBionicWheel
