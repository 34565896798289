import React from 'react'
import styled from 'styled-components'

import BionicBox from '../BionicBox/BionicBox'

const StyledCardHead = styled.div`
    pointer-events: none;

    .card-container {
        position: relative;
        margin-bottom: 1rem;
        padding-top: 15px;
        padding-bottom: 10px;
        border-radius: 3px;
        background-color: ${props => props.theme.bluegrey};
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
        color: ${props => props.theme.mainTextColor};
        line-height: 1.5rem;
    }

    .indicator {
        position: relative;
        margin-right: 5px;
        text-align: right;
    }

    .title {
        display: inline-block;
        vertical-align: top;
    }

    .arrow {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: -10px;
        width: 20px;
        margin: 0 auto;
        border-top: 10px solid #1a3e6b;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;

        &::after,
        &::before {
            position: absolute;
            top: -16px;
            content: '';
            height: 24px;
            border: 1px solid ${props => props.theme.bionicBorder};
        }

        &::before {
            left: -12px;
            transform: rotate(-64deg);
        }

        &::after {
            left: 9px;
            transform: rotate(64deg);
        }
    }

    .arrow-up {
        position: absolute;
        top: -10px;
        content: '';
        border-bottom: 10px solid #29323c;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 20px;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate(0, 0, 0);

        &::after,
        &::before {
            position: absolute;
            top: -8px;
            content: '';
            height: 24px;
            border: 1px solid ${props => props.theme.bionicBorder};
        }

        &::before {
            left: -12px;
            transform: rotate(-116deg);
        }

        &::after {
            left: 9px;
            transform: rotate(116deg);
        }
    }
`

const StyledBionicBox = styled(BionicBox)`
    position: relative;
    min-height: 70px;
    margin-bottom: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${props => props.theme.questionBackground};
`

type Props = {
    title: string
    indicator?: string | number
    optional?: boolean
    arrow?: string
}

const CardHead = ({ indicator = 0, title = '', optional = false, arrow = '' }: Props) => {
    return (
        <StyledCardHead>
            <StyledBionicBox>
                {indicator ? <div className="indicator">{indicator}</div> : null}
                <div className="title">
                    {title}
                    {optional ? ' (Optional)' : ''}
                </div>

                <div className={arrow === 'up' ? 'arrow-up' : 'arrow'} />
            </StyledBionicBox>
        </StyledCardHead>
    )
}

export default CardHead
