import React, { Component } from 'react'
import styled from 'styled-components'

import ResultsTopBanner from './ResultsTopBanner'
import ResultsBionicWheel from './ResultsBionicWheel'
import { ResultsResponse } from './ResultsDefinition'
import { BionicToggleProps } from '../Bionic/BionicToggleRow'

const StyledContainer = styled.div`
    position: relative;
    -webkit-overflow-scrolling: touch;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    backface-visibility: hidden;
    padding: 15px;
    width: 100%;
`

type Props = {
    results: ResultsResponse
} & BionicToggleProps

type State = {
    currentBlock: any
    show: boolean
    showCount: number
    fade: string
    fadeTicker: boolean
}

class ResultsContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            show: true,
            showCount: 0,
            currentBlock: {},
            fade: 'start',
            fadeTicker: false,
        }
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({ currentBlock: {}, fade: 'none' })
    }

    render() {
        const { results, bionic } = this.props
        const { show } = this.state
        return (
            <StyledContainer>
                {show && (
                    <>
                        <div style={{ margin: '50px auto 50px' }}>
                            <ResultsTopBanner results={results} bionic={bionic} />
                        </div>
                        <ResultsBionicWheel results={results} />
                    </>
                )}
            </StyledContainer>
        )
    }
}

export default ResultsContainer
