import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import SwipeableViews from 'react-swipeable-views'
import PreResultSlide from './PreResultSlide.tsx'

const StyledContainer = styled.div`
    position: relative;

    .slides-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
        margin-left: -60px;
        margin-right: 20px;
    }

    .icon {
        position: absolute;
        top: 40%;
        width: 50px;
        height: 50px;
        font-size: 50px;
        line-height: 50px;
        text-align: center;
        color: ${props => props.theme.buttonBackground};

        &:hover {
            opacity: 0.5;
        }
    }

    .arrow-bar {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 55px;
        background-color: ${props => props.theme.preResultsHeadBackground};
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .left {
        left: 0;
    }

    .right {
        right: -2px;
    }

    .transparent {
        background-image: none;
    }

    .desktop-only {
        display: none;

        @media only screen and (min-width: 768px) {
            display: inherit;
        }
    }
`

class PreResultsSlides extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 0,
            swipeDelay: '0s',
            swipeDuration: '0.2s',
            sliderStyles: {
                root: {
                    padding: '0 60px',
                    overflowX: window.innerWidth < 400 ? 'none' : 'hidden',
                },
                slideContainer: {
                    padding: '5px 10px',
                    overflow: 'none',
                    width: '200px',
                },
                slide: {
                    padding: 15,
                    minHeight: 100,
                    overflow: 'none',
                },
            },
        }
        this.swipeableRef = React.createRef()
    }

    componentDidMount() {
        this.setSliderStyles()
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { index } = this.state
        if (newProps.slideIndex !== index) {
            this.setState({ index: newProps.slideIndex })
        }
    }

    handleChangeIndex = index => {
        const { updateCurrentBlock, blocks } = this.props
        const block = blocks[index]
        updateCurrentBlock(block, index)
        this.setState({ index })
    }

    setSliderStyles = () => {
        const { current } = this.swipeableRef
        const { containerNode } = current
        const { clientWidth } = containerNode
        this.setState({
            sliderStyles: {
                root: {
                    padding: clientWidth < 250 ? '0 30px' : '0 60px',
                    overflowX: window.innerWidth < 400 ? 'none' : 'hidden',
                },
                slideContainer: {
                    padding: '5px 10px',
                    overflow: 'none',
                    width: clientWidth < 250 ? '100%' : `${clientWidth}px`,
                },
                slide: {
                    padding: 15,
                    minHeight: 100,
                    overflow: 'none',
                },
            },
        })
    }

    prev = () => {
        const { index } = this.state
        if (index > 0) {
            this.handleChangeIndex(index - 1)
        }
    }

    next = () => {
        const { index } = this.state
        const { blocks } = this.props
        if (index < blocks.length - 1) {
            this.handleChangeIndex(index + 1)
        }
    }

    render() {
        const { blocks, select, profile } = this.props
        const { index, swipeDuration, swipeDelay, sliderStyles } = this.state
        return (
            <StyledContainer>
                <div className="slides-wrapper">
                    <SwipeableViews
                        ref={this.swipeableRef}
                        index={index}
                        enableMouseEvents
                        axis="x"
                        ignoreNativeScroll
                        onChangeIndex={this.handleChangeIndex}
                        style={sliderStyles.root}
                        slideStyle={sliderStyles.slideContainer}
                        springConfig={{
                            duration: swipeDuration,
                            easeFunction: 'cubic-bezier(0.1, 0.35, 0.2, 1)',
                            delay: swipeDelay,
                        }}
                    >
                        {blocks.map(b => (
                            <PreResultSlide
                                key={b.blockId}
                                block={b}
                                select={select}
                                profile={profile}
                            />
                        ))}
                    </SwipeableViews>

                    {index > 0 ? (
                        <div
                            className="arrow-bar left desktop-only"
                            onClick={this.prev}
                            role="button"
                            onKeyPress={() => {}}
                            tabIndex={0}
                        >
                            <div className="icon left transparent">
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </div>
                        </div>
                    ) : null}

                    {index !== blocks.length - 1 && (
                        <div
                            className="arrow-bar right desktop-only"
                            onClick={this.next}
                            role="button"
                            onKeyPress={() => {}}
                            tabIndex={-1}
                        >
                            <div className="icon right transparent">
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                    )}
                </div>
            </StyledContainer>
        )
    }
}

PreResultsSlides.defaultProps = {
    profile: {},
    blocks: [],
    updateCurrentBlock: {},
    select: () => {},
}

PreResultsSlides.propTypes = {
    profile: PropTypes.object,
    blocks: PropTypes.array,
    updateCurrentBlock: PropTypes.func,
    select: PropTypes.func,
}

export default PreResultsSlides
