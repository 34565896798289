import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ProgressBar from './ProgressBar.tsx'

const StyledContainer = styled.div`
    line-height: 10px;
`

const ProgressContainer = props => {
    const { block, progressindex, listblock, selected } = props
    return (
        <StyledContainer>
            {block.questions.map((q, ii) => (
                <ProgressBar
                    key={q.questionId}
                    question={q}
                    current={progressindex === ii}
                    listblock={listblock}
                    selected={selected}
                />
            ))}
        </StyledContainer>
    )
}

ProgressContainer.defaultProps = {
    selected: () => {},
    block: {},
    progressindex: 0,
    listblock: true,
}

ProgressContainer.propTypes = {
    selected: PropTypes.func,
    block: PropTypes.object,
    progressindex: PropTypes.number,
    listblock: PropTypes.bool,
}

export default ProgressContainer
