import React, { Component } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const otherValue = 'OTHER'

const checkIsOther = value => {
    return value === otherValue
}

const DropdownContainer = styled.div`
    margin-bottom: 15px;

    .other-input {
        height: 34px;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-left: 3px;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }
    }
`

const StyledSelect = styled(Select)`
    div {
        border: none;
        border-radius: 5px;
        font-size: 16px;
    }

    svg {
        fill: ${props => props.theme.borderColor};
    }
`

const selectStyles = {
    input: base => ({
        ...base,
        display: 'flex',
        '& input': {
            font: 'inherit',
        },
    }),
    menu: base => ({
        ...base,
        container: {
            paddingBottom: '20px',
        },
    }),
}

class DropdownSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentValue: null,
            other: '',
            showOther: false,
        }
    }

    componentDidMount() {
        const { list } = this.props
        this.setState({
            currentValue: list[0],
        })
    }

    handleChange = selection => {
        const { disableOther } = this.props
        const selectionValue = selection ? selection.value : null
        this.setState({
            currentValue: selectionValue,
            showOther: !disableOther && checkIsOther(selectionValue),
        })
        const { selected } = this.props
        if (!checkIsOther(selectionValue)) {
            selected(selection)
        } else {
            selected({ type: 'other', value: otherValue })
        }
    }

    describeOther = e => {
        const { target } = e
        const { name, value } = target
        this.setState({
            [name]: value,
        })
        const { selected } = this.props
        selected({ value: otherValue, label: value })
    }

    render() {
        const { list, placeholder } = this.props
        const { currentValue, other, showOther } = this.state
        return (
            <DropdownContainer>
                <StyledSelect
                    styles={selectStyles}
                    options={list}
                    defaultValue={currentValue}
                    onChange={this.handleChange}
                    autoFocus={false}
                    isSearchable
                    placeholder={placeholder}
                    isClearable
                />
                {showOther ? (
                    <input
                        className="other-input"
                        placeholder="Please describe other"
                        onChange={this.describeOther}
                        name="other"
                        value={other}
                    />
                ) : null}
            </DropdownContainer>
        )
    }
}

DropdownSelect.defaultProps = {
    list: [],
    placeholder: '',
    selected: () => {},
    disableOther: false,
}

DropdownSelect.propTypes = {
    list: PropTypes.array,
    placeholder: PropTypes.string,
    selected: PropTypes.func,
    disableOther: PropTypes.bool,
}

export default DropdownSelect
