import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

import rightArrow from '../../../assets/customIcons/arrow-right.svg'

const StyledContainer = styled.div`
    height: 100%;
    padding-top: 25px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;

    .title {
        margin-bottom: 90px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .quick-start-row {
        display: flex;
        align-items: center;
    }

    .btn-title {
        margin-right: 15px;
        text-transform: uppercase;
        font-size: 16px;
    }

    .btn-icon {
        width: 24px;
        min-width: 24px;
        height: 24px;
        padding: 0;
        border-radius: 50%;
        background-color: #ffffff;
    }
`

type Props = {
    block: Record<string, any>
    select: Function
}

const BlockQuickStart = ({ block = {}, select = () => {} }: Props) => {
    const onSelect = () => {
        select(block)
    }

    return (
        <StyledContainer onClick={onSelect}>
            <div className="title">{block.title}</div>
            <div className="quick-start-row">
                <div className="btn-title">Quick start</div>
                <Button className="btn-icon">
                    <img alt="Quick start" src={rightArrow} width="7" height="10" />
                </Button>
            </div>
        </StyledContainer>
    )
}

export default BlockQuickStart
