import React from 'react'
import styled from 'styled-components'

const StyledBar = styled.div`
    width: 25px;
    height: 5px;
    display: inline-block;
    vertical-align: top;
    margin-right: 2px;
    margin-left: 2px;
    background-color: white;
`

type Props = {
    listblock: any
    question: Record<string, any>
    current: any
}

const ProgressBar = ({ listblock, question, current }: Props) => {
    const onSelect = () => {
        if (listblock) {
            return
        }
    }

    return (
        <StyledBar
            onClick={onSelect}
            style={{
                opacity: question.answer ? 1 : 0.5,
                backgroundColor: current && !listblock ? 'black' : 'white',
            }}
        />
    )
}

export default ProgressBar
