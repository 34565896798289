import React from 'react'
import styled from 'styled-components'

import BarGraph from '../Elements/BarGraph'

const StyledContainer = styled.div`
    height: 100%;
    padding-top: 25px;
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 20px;

    .title {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;

        @media only screen and (max-width: 321px) {
            font-size: 14px;
        }
    }

    .graphs {
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        bottom: 0;
        padding-left: 20px;
    }

    .graphs-space {
        height: 100px;
    }
`

type Props = {
    profile: Record<string, any>
    block: Record<string, any>
}

const PreResultGraph = ({ block = {}, profile = {} }: Props) => {
    return (
        <StyledContainer>
            <div className="title" dangerouslySetInnerHTML={{ __html: `${block.title}` }} />
            <div className="graphs">
                <BarGraph
                    key="average"
                    fill={block.benchmark.benchmarkScore}
                    title={`Average for ${profile.industry || 'your'} industry`}
                />
                <BarGraph key="user" fill={block.preresults.userScore} title="Your score" />
            </div>
            <div className="graphs-space" />
        </StyledContainer>
    )
}

export default PreResultGraph
