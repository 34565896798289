import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import AppBar from '../AppBar'
import { StyledBackground } from '../Styles'
import { CopyProvider } from '../../contexts/Copy'
import { AppTypeConsumer } from '../../contexts/App'

const bionicTheme = {
    // text
    mainTextColor: '#FFFFFF',
    secondaryTextColor: '#1d5aaa',
    inputPlaceholderColor: '#7e7e7e',
    inputTextColor: '#16304e',
    inputIconColor: '#bcbcbc',
    // background
    mainBackground: '#15202c',
    lightBackground: '#16304e',
    headerBackground: 'rgb(21,37,54)',
    buttonBackground: '#146fe7',
    // intro card
    cardContentBackground: 'radial-gradient(circle at 50% 0, #173458, #142331 174%)',
    cardBorderColor: '#1d5aaa',
    cardListNumbers: '#4ebd6c',
    chevron: '#ffffff',
    // history card
    surveyCardHeaderBackground: 'radial-gradient(circle at 50% 0, #1a3e6b, #152636 101%);',
    deleteIconBackground: '#1d5aaa',
    borderColor: '#146fe7',
    // contact page
    contactEmail: '#00c162',
    // bionic box
    bionicBoxBackground: 'linear-gradient(89deg, #173458 3%, #173457 22%, #142331 97%)',
    bionicBorder: '#1d5aaa',
    // pre-survey page
    questionBackground: 'radial-gradient(circle at 50% 0, #1a3e6b, #152636 62%)',
    optionBackground: '#146fe7',
    optionSelectedBackground: '#00c162',
    // pre results page
    preResultsHeadBackground: '#162c45',
    preResultsBorder: '#979797',
    dashBackground: '#d9dbdd',
    preResultsDivider: '#d3d3d3',
    preResultsCardBackground: 'linear-gradient(179deg, #a8a8a8 1%, #182028 295%, #0e1720 298%)',
    questionCirclesDefault: '#d8d8d8',
    questionCirclesAnswered: '#00c162',
    // slide card
    slideCardBorder: '#ebebeb',
    // questions
    cardBodyBackground: '#ffffff',
    cardTextColor: '#020202',
    // about page
    stageDivider: '#979797',
    stageDividerCircle: '#00c162',
    onboardingCardNumber: '#ffffff',
    // misc
    surveyButton: '#00c162',
    caution: 'red',
    success: '#00c162',
    bcgGreen: '#00512F',

    // remainder from the old theme
    white: '#FFFFFF',
    darkblue: '#10223A',
    darkblueGradient: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
    darkblueText: '#29323c',
    bluegrey: '#29323c',
    grey: '#dedede',
    darkgrey: '#29323c',
    lightgrey: '#f5f5f5',
    selectedGrey: '#9ca4ad',
    shadowGrey: '0px 2px 4px rgba(0, 0, 0, 0.3)',
    shadowGreyLight: '#aeb4b9',
    textGrey: '#5A5A5A',
}

const StyledPage = styled.div`
    min-height: 100vh;
    font-family: 'Proxima Nova', sans-serif !important;
    color: ${props => props.theme.black};

    .loading-icon-wrapper {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        color: ${props => props.theme.bcgGreen};
    }
`

const Inner = styled.div`
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
`

const Page = props => {
    const { children, appbar, background, page, isLightBackground } = props
    return (
        <AppTypeConsumer>
            {([appType]) => {
                return (
                    <ThemeProvider theme={bionicTheme}>
                        <CopyProvider>
                            <StyledPage>
                                {background && (
                                    <StyledBackground page={page} isLight={isLightBackground} />
                                )}
                                {appbar && <AppBar {...props} />}
                                <Inner>{children}</Inner>
                                {/* <CookiesConsent /> */}
                            </StyledPage>
                        </CopyProvider>
                    </ThemeProvider>
                )
            }}
        </AppTypeConsumer>
    )
}

Page.defaultProps = {
    appbar: false,
    children: [],
    background: false,
    page: '',
    isLightBackground: false,
}

Page.propTypes = {
    appbar: PropTypes.bool,
    background: PropTypes.bool,
    page: PropTypes.string,
    isLightBackground: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default Page
