import React from 'react'
import styled from 'styled-components'

import { CardBody } from '../Cards'

const StyledOption = styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin: 5px;

    .option-label {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    .option-description {
        padding-right: 15px;
        padding-bottom: 10px;
    }

    .with-description {
        padding-left: 75px;
        text-align: left;
    }

    .uppercase-label {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
    }

    .bionic & {
        margin: 6px 0;

        .option-label {
            font-family: 'Proxima Nova', sans-serif;
            font-size: 22px;
            font-weight: normal;
        }
    }
`

type Props = {
    select: Function
    option: Record<any, any>
    question: Record<any, any>
}

const SingleSelectOption = ({ select = () => {}, option = {}, question = {} }: Props) => {
    const isSelected =
        question.answer && question.answer.length > 0 && question.answer[0].value === option.value

    const onSelect = () => {
        select(option)
    }

    return (
        <StyledOption onClick={onSelect}>
            <CardBody selected={isSelected}>
                <div
                    className={`option-label ${
                        option.description ? 'uppercase-label with-description' : ''
                    }`}
                >
                    {option.label}
                </div>

                {option.description ? (
                    <div className="option-description with-description">{option.description}</div>
                ) : null}
            </CardBody>
        </StyledOption>
    )
}

export default SingleSelectOption
