import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import michael_tan from '../assets/contacts/michael_tan.png'
import michael_grebe from '../assets/contacts/michael_grebe.png'
import ganga_kannan from '../assets/contacts/ganga_kannan.png'
import ankush_mattu from '../assets/contacts/ankush_mattu.png'

import { Page } from '../components/Page'
import { StyledButton } from '../components/Styles'
import { FeedbackBarContentWrapper } from '../components/Feedback'
import { DropdownSelect } from '../components/FormElements'
import BionicBox from '../components/BionicBox/BionicBox'
import CONTACTS from '../references/contacts'
import { getFeedbackTypes, postFeedback } from '../api'

const StyledPage = styled.div`
    position: relative;
    padding: 20px;
    padding-bottom: 100px;
    background-color: ${props => props.theme.mainBackground};
    min-height: 100vh;

    .page-title {
        margin-top: 100px;
        margin-bottom: 100px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 36px;
        font-weight: normal;
        color: ${props => props.theme.mainTextColor};
    }

    .section-title {
        display: inline-block;
        margin-bottom: 50px;
        font-size: 24px;
        font-weight: normal;
        color: ${props => props.theme.mainTextColor};
    }

    .contact-address {
        margin-top: 25px;
        margin-bottom: 15px;
        font-size: 18px;
        color: ${props => props.theme.mainTextColor};

        a {
            margin-left: 5px;
            color: ${props => props.theme.contactEmail};
            text-decoration: none;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .contact-support {
        min-height: 400px;
    }

    .support-box {
        display: flex;
        align-items: center;
        height: 70px;
        margin-bottom: 15px;
        padding-left: 24px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    textarea {
        width: 100%;
        height: 140px;
        padding: 5px;
        margin: 0 0 15px 0;
        border: none;
        border-radius: 5px;
        font-size: 16px;
    }

    .feedback {
        margin: 0 auto;
        max-width: 350px;
    }

    .no-top-padding {
        padding-top: 0;
    }
`

class ContactPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedValue: [],
            textarea: '',
        }
    }

    async componentDidMount() {
        const res = await getFeedbackTypes()
        if (res.status === 200) {
            this.setState({
                selectionList: res.data,
                selectedValue: res.data[0],
            })
        }
    }

    selected = value => {
        this.setState({ selectedValue: value })
    }

    handleInputChange = e => {
        const { target } = e
        const { name, value } = target
        this.setState({
            [name]: value,
        })
    }

    submitToContact = async e => {
        e.preventDefault()
        const { selectedValue, textarea } = this.state
        if (!selectedValue) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'Please select a message type.',
                },
            })
            return
        }
        if (!textarea.length || textarea === '') {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'Please enter a comment, we look forward to hearing from you.',
                },
            })
            return
        }
        const res = await postFeedback({ type: selectedValue.value, message: textarea })
        if (res.status !== 201) {
            this.setState({
                textarea: '',
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error processing your request, pleast try again.',
                },
            })
        } else {
            this.setState({
                textarea: '',
                feedbackMessage: {
                    type: 'success',
                    text: 'Thank you for your message.',
                },
            })
        }
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const { textarea, feedbackMessage, selectionList } = this.state
        return (
            <Page {...this.props} appbar background>
                <StyledPage>
                    <h1 className="page-title">Contacts</h1>
                    <Grid container>
                        <Grid item md={2} />
                        <Grid item xs={12} sm={6} md={4}>
                            <h2 className="section-title">DAI Team</h2>
                            <div>
                                {CONTACTS.contacts.map((c, i) => (
                                    <ContactItem
                                        className={i === 0 ? 'no-top-padding' : ''}
                                        contact={c}
                                        key={c.contactId}
                                        index={i}
                                    />
                                ))}
                            </div>
                            <p className="contact-address">
                                Reach out to:
                                <a href="mailto:DAI@bcg.com">DAI@bcg.com</a>
                            </p>
                        </Grid>

                        <Grid item xs={12} sm={6} md={5} className="contact-support">
                            <h2 className="section-title">Contact the feedback team</h2>

                            <BionicBox className="support-box">
                                Please let us know what you think
                            </BionicBox>
                            <DropdownSelect
                                list={selectionList}
                                selected={this.selected}
                                placeholder="Select feedback type"
                            />
                            <textarea
                                name="textarea"
                                onChange={this.handleInputChange}
                                value={textarea}
                                placeholder="Type here..."
                            />
                            <StyledButton onClick={this.submitToContact} customClass="narrow">
                                Submit
                            </StyledButton>
                            <br />
                            {feedbackMessage ? (
                                <div className="feedback">
                                    <FeedbackBarContentWrapper
                                        message={feedbackMessage.text}
                                        variant={feedbackMessage.type}
                                        onClose={this.closeFeedbackMessage}
                                    />
                                    <br />
                                </div>
                            ) : (
                                <React.Fragment />
                            )}
                        </Grid>
                        <Grid item md={2} />
                    </Grid>
                </StyledPage>
            </Page>
        )
    }
}

export default ContactPage

const StyledContactItem = styled(Grid)`
    max-width: 400px;
    padding: 15px 0 15px 0;
    color: ${props => props.theme.mainTextColor};

    .text {
        display: block;
        padding-left: 5px;
        font-size: 16px;
    }

    .name {
        font-weight: bold;
    }

    .title {
        font-weight: 200;
    }

    .img-wrapper {
        text-align: center;
    }

    .contact-image {
        margin: 0 auto;
        border: 2px solid ${props => props.theme.bionicBorder};
        border-radius: 50%;

        @media only screen and (min-width: 768px) {
            width: 80px;
            height: 80px;
        }
    }
`

const ContactItem = props => {
    const { contact, index, className } = props
    let imgUrl
    switch (index) {
        case 0:
            imgUrl = michael_tan
            break
        case 1:
            imgUrl = michael_grebe
            break
        case 2:
            imgUrl = ganga_kannan
            break
        case 3:
            imgUrl = ankush_mattu
            break
        default:
            imgUrl = null
            break
    }
    const imgWidth = 70
    return (
        <StyledContactItem
            className={`contact-item ${className}`}
            container
            spacing={0}
            alignItems="center"
        >
            <Grid className="img-wrapper" item xs={3}>
                <img
                    className="contact-image"
                    src={imgUrl}
                    width={imgWidth}
                    height={imgWidth}
                    alt="person"
                />
            </Grid>
            <Grid item xs={9}>
                <span className="text name">{contact.name}</span>
                <span className="text title">{contact.title}</span>
                <span className="text title">{contact.city}</span>
            </Grid>
        </StyledContactItem>
    )
}

ContactItem.defaultProps = {
    index: 0,
    contact: {},
}

ContactItem.propTypes = {
    index: PropTypes.number,
    contact: PropTypes.object,
}
