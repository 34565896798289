import React, { Component } from 'react'
import styled from 'styled-components'
import StyledButton from '../Styles/StyledButton'
import { FeedbackBarContentWrapper } from '../Feedback'
import { postFeedback } from '../../api'
import { Block } from '../Results/ResultsDefinition'

const StyledContainer = styled.div`
    width: 100%;

    .feedback-textarea {
        width: 100%;
        margin: 15px auto;
        height: 150px;
        padding: 10px;
        display: block;
        border: none;
        border-radius: 3px;
        resize: none;
        font-size: 20px;
    }

    .feedback-textarea::placeholder {
        font-size: 20px !important;
        color: ${props => props.theme.darkgrey} !important;
    }

    .feedback {
        max-width: 600px;
        margin: 0 auto;
    }

    .bionic & {
        .feedback-textarea {
            height: 120px;
            padding: 16px;
            border: solid 1px #979797;
            border-radius: 5px;
            font-size: 15px !important;
            color: #666;
            line-height: 1.4 !important;
            outline: 0;
            font-family: 'Proxima Nova Alt' !important;
            font-weight: normal !important;
        }
        .feedback-textarea::placeholder {
            font-size: 15px !important;
            color: #999999 !important;
            line-height: 1.4 !important;
            font-family: 'Proxima Nova Alt' !important;
            font-weight: 300 !important;
        }
    }
`

type Props = {
    block: Block
}

type State = {
    feedback: any
    feedbackMessage: any
}

class GoalFeedback extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            feedback: '',
            feedbackMessage: null,
        }
    }

    handleInputChange = (e: any) => {
        const { target } = e
        const { name, value } = target
        // @ts-ignore
        this.setState({
            [name]: value,
        })
    }

    submitFeedback = async (e: any) => {
        e.preventDefault()
        const { feedback } = this.state
        let { feedbackMessage } = this.state
        if (!feedback || feedback.length === 0) {
            return
        }
        const { block } = this.props
        const res = await postFeedback({ type: `GOALS_${block.title}`, message: feedback })
        if (res.status !== 201) {
            feedbackMessage = {
                type: 'error',
                text: 'Sorry, there was a problem receiving your message. Please try again.',
            }
        } else {
            feedbackMessage = {
                type: 'success',
                text: "We've received your message and will get back to you soon!",
            }
        }
        this.setState({
            feedback: '',
            feedbackMessage,
        })
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const { feedback, feedbackMessage } = this.state
        return (
            <StyledContainer>
                <form onSubmit={this.submitFeedback} style={{ width: '100%' }}>
                    <textarea
                        onChange={this.handleInputChange}
                        name="feedback"
                        value={feedback}
                        className="feedback-textarea"
                        placeholder="What is a key factor that is holding you back from reaching your goal?"
                    />
                    <div>
                        <StyledButton
                            customClass="center narrow large"
                            // @ts-ignore
                            type="submit"
                            onClick={this.submitFeedback}
                        >
                            Share your thoughts
                        </StyledButton>
                    </div>
                </form>
                {feedbackMessage ? (
                    <div className="feedback">
                        <br />
                        <FeedbackBarContentWrapper
                            message={feedbackMessage.text}
                            variant={feedbackMessage.type}
                            onClose={this.closeFeedbackMessage}
                        />
                    </div>
                ) : (
                    <React.Fragment />
                )}
            </StyledContainer>
        )
    }
}

export default GoalFeedback
