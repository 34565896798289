import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import TOOLBAR_IPHONE from '../../assets/onboarding/toolbar_iphone.png'
import TOOLBAR_TABLET from '../../assets/onboarding/toolbar_tablet.png'
import ACTION_ICON from '../../assets/onboarding/action_icon.png'
import ADD_ICON from '../../assets/onboarding/add_icon.png'

const slideDescriptions = {
    tip: 'Install this webapp on your mobile device.',
    tip_1: 'In the toolbar',
    tip_2: 'Tap the action icon  in the toolbar',
    tip_3: "Then select 'Add to homescreen'",
}

const StyledContainer = styled.div`
    padding: 10px;
    border: 1px solid ${props => props.theme.cardBorderColor};
    border-radius: 5px;
    background-color: ${props => props.theme.mainBackground};

    .slide-description {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .number {
        border-radius: 100%;
        border: 3px solid #7a9f92;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 45px;
        margin: 0 auto;
        font-weight: bold;
        font-size: 24px;
        color: #7a9f92;
    }

    .divider {
        width: 50%;
        margin: 20px auto;
        border-bottom: 2px solid #7a9f92;
    }

    .description {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        width: 80%;
        margin: 0 auto;
    }

    .description2 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        width: 80%;
        margin: 0 auto;
        padding: 5px;
    }
`

const OnboardingPWASlide = () => {
    const sliderType = window.innerWidth > 700 ? 'slider-big' : 'slider-small'
    const TOOLBAR = sliderType === 'slider-big' ? TOOLBAR_TABLET : TOOLBAR_IPHONE
    return (
        <StyledContainer>
            <Grid container justify="center" alignItems="center" alignContent="space-around">
                <Grid item xs={12}>
                    <div className="slide-description">
                        <div className="number">Tip</div>
                        <div className="divider" style={{ margin: '5px auto' }} />
                        <div
                            className="description2"
                            style={{ fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px' }}
                        >
                            {slideDescriptions.tip}
                        </div>
                        <img width="100%" src={TOOLBAR} alt="ios-device-toolbar" />
                        <div className="description2">{slideDescriptions.tip_2}</div>
                        <div style={{ width: '20px', margin: '0 auto' }}>
                            <img
                                width="20px"
                                src={ACTION_ICON}
                                alt="ios-action-icon"
                                style={{ margin: '0 auto' }}
                            />
                        </div>
                        <div className="description2">{slideDescriptions.tip_3}</div>
                        <div style={{ width: '50px', margin: '0 auto' }}>
                            <img
                                width="50px"
                                src={ADD_ICON}
                                alt="ios-add-icon"
                                style={{ margin: '0 auto' }}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </StyledContainer>
    )
}

export default OnboardingPWASlide
