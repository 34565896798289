import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { virtualize, autoPlay } from 'react-swipeable-views-utils'
import { mod } from 'react-swipeable-views-core'
import Grid from '@material-ui/core/Grid'
import CarouselDots from './CarouselDots'
import IntroCard from './IntroCard'

const VirtAutoPlaySwipeableViews = autoPlay(virtualize(SwipeableViews))

const styles = {
    root: {
        padding: 0,
        position: 'relative',
    },
}

class InformationCarousel extends React.Component {
    state = {
        index: 0,
        autoplay: true,
    }

    componentDidMount() {
        this.resize()
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        const sliderType = window.innerWidth > 700 ? 'slider-big' : 'slider-small'
        this.setState({
            sliderType,
        })
    }

    handleChangeIndex = index => {
        this.setState({
            index,
            autoplay: true,
        })
    }

    playVideo = () => {
        const { autoplay } = this.state
        this.setState({ autoplay: !autoplay })
    }

    slideRenderer = params => {
        const { index, key } = params
        const innerSlideWidth = window.innerWidth - 30 > 750 ? 750 : window.innerWidth - 30
        const { sliderType } = this.state

        switch (mod(index, 4)) {
            case 0:
                return (
                    <div key={key}>
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            style={{
                                margin: '0px auto',
                                width: `${innerSlideWidth}px`,
                                fontSize: '21px',
                                minHeight: '300px',
                            }}
                        >
                            <Grid item style={{ width: '100%', height: '100%' }}>
                                <IntroCard index={0} playvideo={this.playVideo} />
                            </Grid>
                        </Grid>
                    </div>
                )

            case 1:
                return (
                    <div key={key}>
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            alignContent="space-around"
                            style={{
                                margin: '0px auto',
                                width: `${innerSlideWidth}px`,
                                fontSize: '21px',
                                minHeight: '300px',
                            }}
                        >
                            <Grid item style={{ width: '100%', height: '100%' }}>
                                <IntroCard
                                    index={sliderType === 'slider-big' ? 3 : 1}
                                    playvideo={this.playVideo}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )

            case 2:
                return (
                    <div key={key}>
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            alignContent="space-around"
                            style={{
                                margin: '0px auto',
                                width: `${innerSlideWidth}px`,
                                fontSize: '21px',
                                minHeight: '300px',
                            }}
                        >
                            <Grid item style={{ width: '100%', height: '100%' }}>
                                <IntroCard
                                    index={sliderType === 'slider-big' ? 4 : 2}
                                    playvideo={this.playVideo}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )

            case 3:
                return (
                    <div key={key}>
                        <Grid
                            container
                            alignItems="center"
                            alignContent="space-around"
                            style={{
                                margin: '0 auto',
                                width: `${innerSlideWidth}px`,
                                fontSize: '21px',
                                minHeight: '350px',
                            }}
                        >
                            <Grid item style={{ width: '100%', height: '100%' }}>
                                <IntroCard
                                    index={5}
                                    playvideo={this.playVideo}
                                    innerWidth={innerSlideWidth}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )

            default:
                return null
        }
    }

    render() {
        const { index, autoplay, sliderType } = this.state
        if (!sliderType) {
            return <React.Fragment />
        }
        return (
            <div style={styles.root}>
                <VirtAutoPlaySwipeableViews
                    autoplay={autoplay}
                    enableMouseEvents
                    index={index}
                    slideRenderer={this.slideRenderer}
                    interval={6000}
                    slideCount={3}
                    onChangeIndex={this.handleChangeIndex}
                />
                <div>
                    <CarouselDots
                        dots={3}
                        index={index}
                        onChangeIndex={this.handleChangeIndex}
                        keyInputEnabled={this.props.keyInputEnabled}
                    />
                </div>
            </div>
        )
    }
}

InformationCarousel.propTypes = {
    keyInputEnabled: PropTypes.bool,
}

InformationCarousel.defaultProps = {
    keyInputEnabled: true,
}

export default InformationCarousel
