import React from 'react'
import styled from 'styled-components'

import PreResultGraph from './PreResultGraph'
import BlockQuickStart from '../Elements/BlockQuickStart'

const StyledContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid ${props => props.theme.slideCardBorder};
    border-radius: 5px;
    background-image: ${props => props.theme.preResultsCardBackground};
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    @media only screen and (min-width: 768px) {
        min-height: 190px;
    }
`

type Props = {
    profile: Record<string, any>
    block: Record<string, any>
    select: Function
}

const PreResultSlide = ({ block = {}, select = () => {}, profile = {} }: Props) => {
    const { properties } = block

    return (
        <StyledContainer
            style={{
                backgroundColor: block.preresults ? '' : properties.color,
            }}
        >
            {block.preresults && block.preresults.userScore > -100 ? (
                <PreResultGraph block={block} profile={profile} />
            ) : (
                <BlockQuickStart block={block} select={select} />
            )}
        </StyledContainer>
    )
}

export default PreResultSlide
