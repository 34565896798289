import React, { ReactNode, ReactNodeArray } from 'react'
import styled from 'styled-components'

const StyledCardBody = styled.div`
    position: relative;
    margin: 0 auto;
    color: ${props => props.theme.mainTextColor};
    cursor: pointer;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 48px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: ${props => props.theme.optionBackground};
        font-size: 16px;
        text-align: left;
    }

    .selected {
        background-color: ${props => props.theme.optionSelectedBackground};
    }

    .dropdown {
        .content {
            padding: 0 !important;
        }
    }

    .bionic & {
        box-shadow: none;

        .content {
            border-radius: 4px;
            background-color: #90afbc;
            color: #15202c;
            padding: 2px 30px 5px;
        }

        .selected .content {
            background-color: #15202c;
            color: #fff;
        }
    }
`

type Props = {
    selected?: boolean
    children: string | number | ReactNode | ReactNodeArray
    className?: string
}

const CardBody = ({ selected = false, children = [], className }: Props) => {
    return (
        <StyledCardBody className={className}>
            <div className={`content ${selected ? 'selected' : ''}`}>{children}</div>
        </StyledCardBody>
    )
}

export default CardBody
