import { getBrowser } from './browser'

// simple linear tweening - no easing, no acceleration
Math.linearTween = function(t, b, c, d) {
    return (c * t) / d + b
}

// quadratic easing in - accelerating from zero velocity
Math.easeInQuad = function(t, b, c, d) {
    t /= d
    return c * t * t + b
}

// quadratic easing out - decelerating to zero velocity
Math.easeOutQuad = function(t, b, c, d) {
    t /= d
    return -c * t * (t - 2) + b
}

// quadratic easing in/out - acceleration until halfway, then deceleration
Math.easeInOutQuad = function(t, b, c, d) {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
}

const scrollTo = (to, duration, browser) => {
    let browserObj = {}
    if (!browser || browser === 'undefined') {
        browserObj = getBrowser()
        browser = browserObj.name.toLowerCase()
    }
    if (browser === 'chrome') {
        window.scroll({
            top: to,
            behavior: 'smooth',
        })
        return
    } else if (browser === 'ie') {
        window.scrollTo(0, to)
        return
    }

    const element =
        browser !== 'chrome' && browser !== 'firefox' && browser !== 'ie'
            ? document.body
            : document.documentElement
    const start = element.scrollTop
    const change = to - start
    const increment = 20
    let currentTime = 0

    const animateScroll = () => {
        currentTime += increment

        const val = Math.easeInOutQuad(currentTime, start, change, duration)
        element.scrollTop = val

        if (currentTime < duration) {
            setTimeout(animateScroll, increment)
        }
    }

    animateScroll()
}

const freezeScroll = value => {
    const element = document.documentElement
    const { body } = document
    if (value === 'freeze') {
        element.style.overflow = 'hidden'
        element.style.position = 'fixed'
        body.style.position = 'fixed'
    } else {
        element.style.overflow = 'inherit'
        element.style.position = 'relative'
        body.style.position = 'relative'
    }
}

export { scrollTo, freezeScroll }
