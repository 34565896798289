import React, { Component } from 'react'
import styled from 'styled-components'
import Star from './Star'

const StarsContainer = styled.div`
    .stars {
        position: relative;

        .stars-foreground {
            top: 0;
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;
        }
    }
`
const starsNum = [1, 2, 3, 4]
const starsMap = [0, 0, 33, 66, 100]

type Props = {
    fill: number
    readOnly: boolean
    starSize: number
    selected?: (value: number) => void
    center?: boolean
    fillColor?: string
    bgColor?: string
    starMargin?: number
}
type State = {
    fill: number
}

export default class Stars extends Component<Props, State> {
    starsRef: React.Ref<any>

    constructor(props: Props) {
        super(props)
        this.state = {
            fill: 0,
        }
        this.starsRef = React.createRef()
    }

    componentDidMount() {
        const { fill } = this.props
        this.setState({
            fill: fill || 0,
        })
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        const { fill } = newProps
        this.setState({
            fill,
        })
    }

    starSelected = (index: number) => {
        const { fill } = this.state
        let fillNew = Math.floor((index / starsNum.length) * 100)
        if (index === 1 && fill === 25) {
            fillNew = 0
        }
        const { selected } = this.props
        this.setState(
            {
                fill: fillNew,
            },
            () => {
                if (fillNew !== 0 && selected) {
                    selected(starsMap[index])
                }
            }
        )
    }

    render() {
        const { fill } = this.state
        const { readOnly, starSize, center, fillColor, bgColor, starMargin } = this.props
        const inBetweenStarPadding = 10
        const width = `${starSize * 4 + inBetweenStarPadding * 4}px`
        return (
            <StarsContainer style={{ width, margin: center ? '0 auto' : '0' }}>
                <div className="stars">
                    {starsNum.map(star => (
                        <Star
                            size={starSize}
                            index={star}
                            key={star}
                            color={bgColor || '#dedede'}
                            readOnly={readOnly}
                            selected={this.starSelected}
                            margin={starMargin}
                            noPointers={false}
                        />
                    ))}
                    <div className="stars-foreground" style={{ width: `${fill}%` }}>
                        <div className="inner" style={{ width }}>
                            {starsNum.map(star => (
                                <Star
                                    size={starSize}
                                    index={star}
                                    key={star}
                                    color={fillColor || '#ffcc00'}
                                    selected={this.starSelected}
                                    readOnly={readOnly}
                                    margin={starMargin}
                                    noPointers={false}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </StarsContainer>
        )
    }
}
