import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonBase } from '@material-ui/core'
import { StyledButton } from '../Styles'
import { FeedbackBarContentWrapper } from '../Feedback'
import { validateEmailFormat } from '../../utils'
import { resetPassword } from '../../api'

const StyledContainer = styled.div`
    background-color: ${props => props.theme.mainBackground};
    color: ${props => props.theme.mainTextColor};

    .header {
        text-align: center;
        font-size: 20px;
    }

    .subheader {
        text-align: center;
        font-size: 17px;
        padding: 15px;
        margin-bottom: 20px;
    }

    form {
        margin: 0 auto;

        input {
            width: 300px;
            height: 34px;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 50px;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            color: ${props => props.theme.inputTextColor};
            -webkit-appearance: none;

            :focus {
                outline: none;
            }

            ::-webkit-input-placeholder {
                color: ${props => props.theme.inputPlaceholderColor};
            }

            :-moz-placeholder {
                /* Firefox 18- */
                color: ${props => props.theme.inputPlaceholderColor};
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                color: ${props => props.theme.inputPlaceholderColor};
            }

            :-ms-input-placeholder {
                color: ${props => props.theme.inputPlaceholderColor};
            }
        }

        .request {
            button {
                height: 35px;
                width: 180px;
                text-transform: none;
                line-height: 5px;
                font-size: 16px !important;
            }
        }

        .back {
            margin: 5px auto;
            button {
                margin: 0 auto;
                width: 180px;
                text-decoration: underline;
                text-transform: none;
                font-size: 16px;
                text-align: center;
            }
        }
    }

    .feedback {
        max-width: 300px;
        margin: 0 auto;
    }
`

class PasswordReset extends Component {
    state = {
        resetEmail: '',
        feedbackMessage: null,
    }

    handleInputChange = e => {
        const { name, value } = e.target
        this.setState({
            [name]: value,
            feedbackMessage: null,
        })
    }

    resetPassword = async e => {
        e.preventDefault()
        const { resetEmail } = this.state
        if (!validateEmailFormat(resetEmail)) {
            this.setState({
                feedbackMessage: { type: 'error', text: 'Please enter a valid email' },
            })
            return
        }
        const res = await resetPassword(resetEmail)
        if (res.status !== 200) {
            this.setState({
                resetEmail: '',
                feedbackMessage: { type: 'error', text: 'There was an error, please try again.' },
            })
        } else {
            this.setState({
                resetEmail: '',
                feedbackMessage: {
                    type: 'success',
                    text: 'Please check your email for a password reset link.',
                },
            })
        }
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const { resetEmail, feedbackMessage } = this.state
        const { closeDialog } = this.props
        return (
            <StyledContainer>
                <p className="header">Forgot your password?</p>
                <p className="subheader">
                    Enter your email address below to receive a new password
                </p>
                <form onSubmit={this.resetPassword}>
                    <input
                        name="resetEmail"
                        value={resetEmail}
                        onChange={this.handleInputChange}
                        placeholder="Enter your e-mail *"
                    />
                    {feedbackMessage ? (
                        <div className="feedback">
                            <FeedbackBarContentWrapper
                                message={feedbackMessage.text}
                                variant={feedbackMessage.type}
                                onClose={this.closeFeedbackMessage}
                            />
                            <br />
                        </div>
                    ) : (
                        <React.Fragment />
                    )}
                    <div className="request">
                        <StyledButton
                            type="submit"
                            onClick={this.resetPassword}
                            customClass="center"
                        >
                            Request reset link
                        </StyledButton>
                    </div>
                    <div className="back">
                        <ButtonBase onClick={closeDialog}>Back to Login</ButtonBase>
                    </div>
                </form>
                <br />
            </StyledContainer>
        )
    }
}

PasswordReset.defaultProps = {
    closeDialog: () => {},
}

PasswordReset.propTypes = {
    closeDialog: PropTypes.func,
}

export default PasswordReset
