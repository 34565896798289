import React, { createContext, useState } from 'react'

export const AppTypeContext = createContext([])

const initialState = {}

const AppProvider = ({ children }) => {
    const value = useState(initialState)
    return <AppTypeContext.Provider value={value}>{children}</AppTypeContext.Provider>
}

export const AppTypeConsumer = AppTypeContext.Consumer

export default AppProvider
