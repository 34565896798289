import React from 'react'
import styled from 'styled-components'

import timeArrow from '../../../assets/bionic/time-arrow.svg'
import { Block } from '../../Results/ResultsDefinition'
import PreResultsScore from './PreResultsScore'
import { COLOR_SCORE } from '../../Bionic/BionicScoreCircle'

const IndicatorsContainer = styled.div`
    .score-area {
        margin-bottom: 5px;
    }

    .time {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .minutes {
        margin-right: 10px;
    }

    .points-area {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 400px) {
            display: none;
        }
    }

    .point {
        width: 8px;
        height: 8px;
        margin-right: 5px;
        border-radius: 50%;
        background-color: ${props => props.theme.questionCirclesDefault};
    }

    .answered {
        background-color: ${props => props.theme.questionCirclesAnswered};
    }

    .no-margin-right {
        margin-right: 0;
    }
`

type IndicatorProps = {
    block: Block
}

const PreResultsIndicators = ({ block }: IndicatorProps) => {
    const minutesLabel = `minute${block.questions.length === 1 ? '' : 's'}`
    return (
        <IndicatorsContainer>
            {block.preresults ? (
                <div className="score-area">
                    <PreResultsScore
                        fontSize={11}
                        size={20}
                        score={block.preresults.userScore}
                        label=""
                        borderColor={COLOR_SCORE}
                        fontWeight="normal"
                        screen="xs"
                    />
                    <PreResultsScore
                        fontSize={15}
                        size={30}
                        score={block.preresults.userScore}
                        label=""
                        borderColor={COLOR_SCORE}
                        fontWeight="normal"
                        screen="sm"
                    />
                </div>
            ) : (
                <>
                    <div className="time">
                        <span className="minutes">{`${block.questions.length} ${minutesLabel}`}</span>
                        {<img alt="Arrow right" src={timeArrow} width="24" height="24" />}
                    </div>
                    <div className="points-area">
                        {block.questions.map((it, i, arr) => {
                            return (
                                <div
                                    key={it.questionId}
                                    className={`point ${it.answer ? 'answered' : ''} ${
                                        i === arr.length - 1 ? 'no-margin-right' : ''
                                    }`}
                                />
                            )
                        })}
                    </div>
                </>
            )}
        </IndicatorsContainer>
    )
}

export default PreResultsIndicators
