import React, { Component } from 'react'
import styled from 'styled-components'

import { scrollTo } from '../../utils'
import { ResultsWithFilters } from '../../pages/ResultsPage'
import GoalTopBanner from './GoalTopBanner'
import GoalsBionicWheel from './GoalsBionicWheel'
import FilterBar from '../Filters/FilterBar'
import { Block } from '../Results/ResultsDefinition'
import { BionicToggleProps } from '../Bionic/BionicToggleRow'

const StyledContainer = styled.div`
    position: relative;
    -webkit-overflow-scrolling: touch;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    backface-visibility: hidden;
    padding: 15px;
    width: 100%;
`

type Props = {
    results: ResultsWithFilters
    applyFilters: (filters: any) => void
    resetFilters: (filters: any) => void
    currentIndex: number
} & BionicToggleProps

type State = {
    show: boolean
    showCount: number
    currentBlock: Partial<Block>
    fade: string
    fadeTicker: boolean
}

class GoalsContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            show: true,
            showCount: 0,
            currentBlock: {},
            fade: 'start',
            fadeTicker: false,
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        // we use showCount to trigger animations when the user first sees container
        const { showCount } = this.state
        scrollTo(1, 0)
        const { currentIndex } = newProps
        this.setState({ currentBlock: {}, fade: 'none' })
        if (showCount < 1 && currentIndex == 2) {
            this.setState({ show: false }, () => {
                this.setState({ show: true, showCount: 1 })
            })
        }
    }

    render() {
        const { show } = this.state
        const { results, applyFilters, resetFilters, bionic, setBionic } = this.props
        return (
            <StyledContainer>
                {show && (
                    <>
                        <div style={{ margin: '50px auto 0' }}>
                            <GoalTopBanner bionic={bionic} />
                        </div>
                        <FilterBar
                            results={results}
                            applyFilters={applyFilters}
                            resetFilters={resetFilters}
                            bionic={true}
                            setBionic={setBionic}
                        />
                        <GoalsBionicWheel results={results} />
                    </>
                )}
            </StyledContainer>
        )
    }
}

export default GoalsContainer
