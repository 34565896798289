import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { TERMS_URI } from '../../utils'

const StyledContainer = styled.div`
    .terms {
        cursor: pointer;
        font-family: 'Proxima Nova Alt', sans-serif;

        button {
            width: 15px;
            height: 15px;
            background: transparent;
            border: 1px solid ${props => props.theme.mainTextColor};
            display: inline-block;
            vertical-align: top;

            &:focus {
                outline: none;
            }
        }

        .tick {
            color: ${props => props.theme.mainTextColor};
        }

        .terms-text {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            margin-top: -1px;
            font-size: 14px;
            color: ${props => props.theme.mainTextColor};
        }

        .terms-link {
            color: ${props => props.theme.mainTextColor};
        }
    }

    .terms:focus {
        outline: none;
    }
`

const TermsCheck = props => {
    const { toggleTerms, checked } = props
    return (
        <StyledContainer>
            <div
                className="terms"
                tabIndex="-1"
                onClick={toggleTerms}
                onKeyDown={toggleTerms}
                role="button"
            >
                <button type="button">
                    {checked ? <FontAwesomeIcon className="tick" icon={faCheck} /> : null}
                </button>
                <div className="terms-text">
                    I accept the{' '}
                    <a
                        href={TERMS_URI}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="terms-link"
                    >
                        Terms and Conditions{' '}
                    </a>
                </div>
            </div>
        </StyledContainer>
    )
}

TermsCheck.defaultProps = {
    checked: false,
    toggleTerms: () => {},
}

TermsCheck.propTypes = {
    checked: PropTypes.bool,
    toggleTerms: PropTypes.func,
}

export default TermsCheck
