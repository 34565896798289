import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const StyledContainer = styled.div`
    color: ${props => props.theme.success} !important;
    display: block;
    margin: 0 auto;
    text-align: center;
`

const StyledSpinner = () => {
    return (
        <StyledContainer>
            <FontAwesomeIcon icon={faSpinner} spin />
        </StyledContainer>
    )
}

export default StyledSpinner
