import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
    .red {
        background-color: ${props => props.theme.caution} !important;
    }

    .narrower {
        height: 35px;
        line-height: 5px;
    }

    .narrow {
        height: 45px;
        line-height: 5px;
    }

    .thick {
        height: 50px;
        line-height: 15px;
    }

    .short {
        max-width: 100px;
    }

    .medium {
        max-width: 200px;
    }

    .large {
        width: 250px;
        max-width: 250px;
    }

    .center {
        margin: 0 auto;
    }

    .bold {
        font-weight: bold;
    }

    .caution {
        background: ${props => props.theme.caution} !important;
    }

    .disabled {
        background: #ccc !important;

        &:hover {
            background: #ccc !important;
        }
    }
`

const StyledButton = styled(Button)`
    && {
        display: block;
        width: 100%;
        padding: 1rem;
        border-radius: 5px;
        background-color: ${props => props.theme.buttonBackground};
        color: ${props => props.theme.mainTextColor};
        font-family: 'Proxima Nova', 'sans-serif';
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            background-color: ${props => props.theme.buttonBackground};
            color: ${props => props.theme.mainTextColor};
            opacity: 0.8;
        }
    }

    &.bionic-green {
        background-color: ${props => props.theme.surveyButton};
        font-weight: normal;
        font-size: 19px;
        text-transform: none;

        &:hover {
            background-color: ${props => props.theme.surveyButton};
            color: ${props => props.theme.mainTextColor};
            opacity: 0.8;
        }
    }

    .bionic & {
        font-size: 16px;
        font-family: 'Proxima Nova', 'sans-serif';
        height: 45px;

        &.bionic-dark {
            background-color: #17304f;
            text-transform: none;
            font-size: 19px;
            font-weight: normal;
        }
    }
`

const CustomButton = props => {
    const { children, customClass, onClick, type, disabled } = props
    return (
        <ButtonWrapper>
            <StyledButton
                type={type || 'button'}
                disabled={disabled}
                onClick={onClick}
                className={customClass}
            >
                {children}
            </StyledButton>
        </ButtonWrapper>
    )
}

CustomButton.defaultProps = {
    customClass: '',
    onClick: () => {},
    children: {},
}

CustomButton.propTypes = {
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default CustomButton
