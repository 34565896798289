/* eslint-disable */
// prettier-ignore
const data = {
    "blocks": [
        {
            "blockId": 9,
            "blockThemeTitle": "Strategy & Purpose",
            "title": "Strategy & Purpose",
            "text": "Articulate and embed a timeless purpose, and then build a bionic strategy powered by digital and people to support it",
            "properties": {
                "color": "#2A5E7E",
            },
        },
        {
            "blockId": 10,
            "blockThemeTitle": "Technology",
            "title": "Data & AI",
            "text": "Develop strategic and proprietary data sources to power AI-driven insights and democratize data access to enable teams to build products by separating the data layer from underlying transactional systems",
            "properties": {
                "color": "#5CB67A",
            },
        },
        {
            "blockId": 11,
            "blockThemeTitle": "Technology",
            "title": "Modular Technology",
            "text": "Construct a modern, modular, layered architecture through a next gen tech function using DevOps, API and microservice management, and cybersecurity",
            "properties": {
                "color": "#5CB67A",
            },
        },
        {
            "blockId": 12,
            "blockThemeTitle": "Outcomes",
            "title": "Bionic Operations",
            "text": "Improve planning, optimize output, and gain real-time end-to-end process insights through the latest technology. Use agile teams to preserve autonomy and constantly drive business value",
            "properties": {
                "color": "#60BDD4",
            },
        },
        {
            "blockId": 13,
            "blockThemeTitle": "Outcomes",
            "title": "Personalized Customer Experiences & Relationships",
            "text": "Leverage data and AI to build one-to-one customer offers and interactions",
            "properties": {
                "color": "#60BDD4",
            },
        },
        {
            "blockId": 14,
            "blockThemeTitle": "Outcomes",
            "title": "New Offers, Services and Business Models",
            "text": "Disrupt the market and yourself with agile, business-building teams driving innovation at speed and scale",
            "properties": {
                "color": "#60BDD4",
            },
        },
        {
            "blockId": 15,
            "blockThemeTitle": "Human",
            "title": "Digital Talent",
            "text": "Identify new talent and skills along with fundamentally different leadership models, sourcing talent both internally (e.g. through upskilling the workforce) and externally, e.g. through integrated ecosystems",
            "properties": {
                "color": "#2A5E7E",
            },
        },
        {
            "blockId": 16,
            "blockThemeTitle": "Human",
            "title": "Dynamic Platform Organizations",
            "text": "Build dynamic, multidisciplinary, modular teams, empower them with autonomy and enable them to work in agile ways",
            "properties": {
                "color": "#2A5E7E",
            },
        }
    ],
}

export default data
