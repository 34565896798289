import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonBase } from '@material-ui/core'
import { Page } from '../components/Page'
import { InformationCarousel } from '../components/Home'
import { HistoryCardsCarousel } from '../components/History'
import { StyledButton, StyledSpinner } from '../components/Styles'
import SimpleDialog from '../components/SimpleDialog'
import { TermsCheck } from '../components/FormElements'
import { createPresurvey, onboardingSeen, termsConfirmed } from '../api'
import UserContext from '../contexts/User'
import OnboardingCarousel from '../components/Onboarding/OnboardingCarousel'
import { FeedbackBar, FeedbackBarContentWrapper } from '../components/Feedback'
import { scrollTo, routePaths } from '../utils'

const StyledPage = styled.div`
    position: relative;
    height: 100%;
    padding-bottom: 100px;
    opacity: 0;
    background-color: ${props => props.lightBackground};
    color: ${props => props.theme.mainTextColor};
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;

    .history-section {
        position: relative;
        max-width: 100vw;
        overflow-x: hidden;
        margin: 0 auto;

        p {
            text-align: center;
            color: #ffffff;
            font-size: 24px;
            font-weight: bold;
        }

        .history-link {
            display: block;
            height: 30px;
            margin: 0 auto;
            text-align: center;
            color: #ffffff;
            font-size: 16px !important;
            text-decoration: underline;
        }
    }

    .hero {
        width: 100%;
        background-color: #15202c;
        transform: translate3d(0, 0, 0);
        margin-bottom: 30px;
    }

    .hero:after {
        content: '';
        position: absolute;
        top: 100%;
        width: 0;
        height: 0;
        border-top: solid 30px #15202c;
        border-left: solid calc(100vw / 2) transparent;
        border-right: solid calc(100vw / 2) transparent;
    }
`

class HomePage extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {
            surveys: [],
            dialogOpen: false,
            onboardingDialogOpen: false,
            terms: false,
            loaded: false,
            opacity: 0,
        }
        this._mounted = false
    }

    async componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            opacity: 1,
        })
        this._mounted = true
        await this.getProfile()
    }

    componentWillUnmount() {
        this._mounted = false
    }

    async getProfile() {
        const { getProfile } = this.context
        await getProfile()
        if (!this._mounted) {
            return
        }
        this.setFlags()
        this.setSurveys()
    }

    setFlags() {
        const { user } = this.context
        if (!user.onboardingSeen) {
            this.setState({ onboardingDialogOpen: true })
        }
        if (!user.termsConfirmed) {
            this.setState({
                dialogOpen: true,
            })
        }
        if (user.status === 'deletion_requested') {
            this.setState({
                feedbackMessage: {
                    type: 'warning',
                    text: 'Your request to delete your account is pending.',
                },
            })
        }
    }

    setSurveys = () => {
        const { user } = this.context
        let surveys
        if (user.history.length < 1) {
            surveys = []
        } else if (user.history.length > 3) {
            surveys = [user.history[0], user.history[1], user.history[2]]
        } else {
            surveys = user.history
        }
        this.setState({
            loaded: true,
            surveys,
        })
    }

    startNewSurvey = async () => {
        const { user } = this.context
        const res = await createPresurvey({ userId: user.userId })
        if (res.status !== 201) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error starting the survey. Please try again.',
                },
            })
            return
        }

        const { history } = this.props
        history.push({
            pathname: routePaths.presurvey,
            state: { presurveyId: res.data.presurveyId },
        })
    }

    updateHistory = deletedSurvey => {
        const { surveys } = this.state
        const filteredSurveys = surveys.filter((item, index, arr) => {
            return item.presurvey.presurveyId !== deletedSurvey.presurvey.presurveyId
        })
        this.setState(
            {
                surveys: filteredSurveys,
            },
            async () => {
                await this.getProfile()
            }
        )
    }

    toggleDialog = () => {
        const { dialogOpen } = this.state
        this.setState({
            dialogOpen: !dialogOpen,
        })
    }

    closeOnboarding = async () => {
        const { user } = this.context
        const { onboardingDialogOpen } = this.state
        this.setState(
            {
                onboardingDialogOpen: !onboardingDialogOpen,
            },
            () => {
                onboardingSeen(user.userId)
            }
        )
    }

    toggleTerms = async () => {
        const { user } = this.context
        const { terms } = this.state
        this.setState(
            {
                terms: !terms,
                dialogOpen: false,
            },
            () => {
                termsConfirmed(user.userId)
            }
        )
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    nav = e => {
        scrollTo(0, 0)
        const { name } = e.currentTarget
        const { history } = this.props
        history.push(routePaths[name])
    }

    render() {
        const {
            surveys,
            dialogOpen,
            terms,
            onboardingDialogOpen,
            feedbackMessage,
            loaded,
            opacity,
        } = this.state

        let recentHistory = null
        if (!loaded) {
            recentHistory = (
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <div style={{ padding: '15px' }}>Loading recent activity</div>
                    <StyledSpinner />
                </div>
            )
        } else {
            recentHistory = (
                <>
                    {surveys.length > 0 ? (
                        <div className="history-section">
                            <p>Recent activity</p>
                            <HistoryCardsCarousel
                                surveys={surveys}
                                continueSurvey={this.continueSurvey}
                                updateHistory={this.updateHistory}
                                {...this.props}
                            />
                            {surveys.length >= 3 ? (
                                <ButtonBase
                                    className="history-link"
                                    onClick={this.nav}
                                    name="history"
                                >
                                    View complete history
                                </ButtonBase>
                            ) : null}
                        </div>
                    ) : null}
                </>
            )
        }

        return (
            <Page {...this.props} appbar background page="home" isLightBackground={true}>
                <StyledPage style={{ opacity }}>
                    <div className="hero">
                        <InformationCarousel keyInputEnabled={!onboardingDialogOpen} />
                    </div>
                    <br />

                    {surveys.length > 0 ? (
                        <StyledButton
                            onClick={this.startNewSurvey}
                            customClass="thick large center"
                        >
                            Start a new survey
                        </StyledButton>
                    ) : (
                        <StyledButton
                            onClick={this.startNewSurvey}
                            customClass="thick large center"
                        >
                            Start a new survey
                        </StyledButton>
                    )}

                    <br />
                    <br />
                    <br />

                    {recentHistory}
                </StyledPage>

                <SimpleDialog key="terms" dialogOpen={dialogOpen}>
                    <div>
                        <p>
                            We have updated our terms, please confirm that you agree to the new
                            terms to continue using the app
                        </p>
                        <TermsCheck toggleTerms={this.toggleTerms} checked={terms} />
                    </div>
                </SimpleDialog>

                <SimpleDialog
                    key="onboarding"
                    dialogOpen={onboardingDialogOpen}
                    closeDialog={this.closeOnboarding}
                >
                    <OnboardingCarousel />
                </SimpleDialog>

                {feedbackMessage && (
                    <FeedbackBar open={!!feedbackMessage}>
                        <FeedbackBarContentWrapper
                            message={feedbackMessage.text}
                            variant={feedbackMessage.type}
                            onClose={this.closeFeedbackMessage}
                        />
                    </FeedbackBar>
                )}
            </Page>
        )
    }
}

HomePage.defaultProps = {
    history: {},
    user: {},
}

HomePage.propTypes = {
    history: PropTypes.object,
    user: PropTypes.object,
}

export default HomePage
