import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { Page } from '../components/Page'
import UserContext from '../contexts/User'
import { StyledButton, StyledSpinner } from '../components/Styles'
import { routePaths, scrollTo } from '../utils'
import { createPresurvey, fetchHistory } from '../api'
import { FeedbackBar, FeedbackBarContentWrapper } from '../components/Feedback'
import { HistoryCardContainer } from '../components/History'

const StyledPage = styled.div`
    position: relative;
    min-height: 100vh;
    padding: 20px;
    padding-bottom: 100px;
    background-color: ${props => props.theme.mainBackground};
    color: ${props => props.theme.mainTextColor};

    .grid-contents {
        max-width: 940px;
        margin: 0 auto;
        padding-top: 100px;

        .section-title-wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 120px;
        }

        .section-title {
            font-size: 44px;
            padding: 0 30px 10px 0;
            color: ${props => props.theme.mainTextColor};
            display: inline-block;
            margin-bottom: 15px;
            text-align: center;
        }
    }

    .loading-text {
        text-align: center;
    }

    .no-surveys-text {
        text-align: center;
    }
`

class HistoryPage extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {
            surveys: [],
            dialogOpen: false,
            nextPage: 0,
            loading: true,
        }
        this._mounted = false
    }

    async componentDidMount() {
        scrollTo(0, 0)
        this._mounted = true
        await this.updateHistory()
    }

    componentWillUnmount() {
        this._mounted = false
    }

    updateHistory = async deletedSurvey => {
        const { surveys } = this.state
        const filteredSurveys = surveys.filter(item => {
            return item.presurvey.presurveyId !== deletedSurvey.presurvey.presurveyId
        })
        this.setState({
            surveys: filteredSurveys,
        })
        const res = await fetchHistory(0)
        if (res && res.status === 200 && this._mounted) {
            const { data } = res
            const { next, history } = data
            this.setState({
                surveys: history,
                feedbackMessage: null,
                nextPage: next,
                loading: false,
            })
        } else if (this.is_mounted) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an issue retrieving your history. Please try again soon.',
                },
                loading: false,
            })
        }
    }

    viewMore = async () => {
        const { nextPage } = this.state
        this.setState({
            loading: true,
        })
        const res = await fetchHistory(nextPage)
        if (res.status !== 200) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'There was an error retrieving your history.',
                },
                loading: false,
            })
        } else {
            const { surveys } = this.state
            const moreSurveys = [...surveys, ...res.data.history]
            this.setState({
                surveys: moreSurveys,
                loading: false,
                nextPage: res.data.next,
            })
        }
    }

    startNewSurvey = async () => {
        const { user } = this.context
        const res = await createPresurvey({ userId: user.userId })
        if (res.status !== 201) {
            return
        }

        const { history } = this.props
        history.push({
            pathname: routePaths.presurvey,
            state: { presurveyId: res.data.presurveyId },
        })
    }

    deleteSurvey = surveyToDelete => {
        this.setState({
            dialogOpen: true,
            surveyToDelete,
        })
    }

    toggleDialog = () => {
        const { dialogOpen } = this.state
        this.setState({
            dialogOpen: !dialogOpen,
        })
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const { surveys, feedbackMessage, nextPage, loading } = this.state
        return (
            <Page {...this.props} appbar background>
                <StyledPage>
                    <div className="grid-contents">
                        <div className="section-title-wrapper">
                            <div className="section-title">History</div>
                        </div>

                        <Grid container spacing={16}>
                            {surveys.length > 0 ? (
                                <>
                                    {surveys.map((it, i) => (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            key={i}
                                            style={{ marginBottom: '10px' }}
                                        >
                                            <HistoryCardContainer
                                                historyItem={it}
                                                key={it.presurvey.presurveyId}
                                                updateHistory={this.updateHistory}
                                                continueSurvey={this.continueSurvey}
                                                {...this.props}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {!loading && (
                                        <Grid item xs={12}>
                                            <p className="no-surveys-text">
                                                You have not yet started a survey.
                                            </p>
                                            <br />
                                            <StyledButton
                                                onClick={this.startNewSurvey}
                                                customClass="thick medium center"
                                            >
                                                Start a new survey
                                            </StyledButton>
                                        </Grid>
                                    )}
                                </>
                            )}

                            {nextPage && !loading ? (
                                <Grid item xs={12}>
                                    <StyledButton
                                        customClass="center narrow medium"
                                        onClick={this.viewMore}
                                    >
                                        View More
                                    </StyledButton>
                                </Grid>
                            ) : null}

                            {loading && (
                                <Grid item xs={12}>
                                    <StyledSpinner />
                                    <br />
                                    <div className="loading-text">Loading history</div>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </StyledPage>

                {feedbackMessage && (
                    <FeedbackBar open={!!feedbackMessage}>
                        <FeedbackBarContentWrapper
                            message={feedbackMessage.text}
                            variant={feedbackMessage.type}
                            onClose={this.closeFeedbackMessage}
                        />
                    </FeedbackBar>
                )}
            </Page>
        )
    }
}

HistoryPage.defaultProps = {
    history: {},
}

HistoryPage.propTypes = {
    history: PropTypes.object,
}

export default HistoryPage
