import React from 'react'
import styled from 'styled-components'
import PreResultItem from './PreResultItem'

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
`

type Props = {
    blocks: any[]
    currentBlock: Record<string, any>
    updateCurrentBlock: Function
}

const PreResultsList = ({
    blocks = [],
    currentBlock = {},
    updateCurrentBlock = () => {},
}: Props) => {
    return (
        <StyledContainer>
            {blocks.map((b, i) => (
                <PreResultItem
                    key={b.blockId}
                    index={i}
                    block={b}
                    currentBlock={currentBlock}
                    updateCurrentBlock={updateCurrentBlock}
                />
            ))}
        </StyledContainer>
    )
}

export default PreResultsList
