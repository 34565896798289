import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import YouTube from './YouTube'
import { CopyConsumer } from '../../contexts/Copy'

const StyledContainer = styled.div`
    .intro-text {
        min-height: 400px;
        margin: 0 auto;
        font-size: 24px;
        color: ${props => props.theme.mainTextColor};
    }

    .card {
        min-height: 350px;
        font-family: 'Proxima Nova Alt', sans-serif;
        color: ${props => props.theme.mainTextColor};

        .inner-content {
            margin-top: 15px;
            padding: 15px;
            border: 1px solid ${props => props.theme.cardBorderColor};
            border-radius: 4px;
            background-image: ${props => props.theme.cardContentBackground};
        }

        .numbers {
            display: flex;
            flex-direction: column;
        }

        .numbers-heading {
            font-size: 19px;
            font-weight: normal;
            text-align: center;
            margin-bottom: 20px;
        }

        .numbers-area {
            display: flex;
            align-items: start;
            justify-content: center;
        }

        .numbers-circle {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .numbers-text {
            display: block;
            width: 80px;
            text-align: center;
            text-transform: uppercase;
        }

        .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 88px;
            height: 88px;
            margin-bottom: 10px;
            border-radius: 50%;
            border: 2px solid;
            font-size: 49px;
            font-weight: 300;
        }

        .circle-left {
            margin-right: 30px;
        }

        .circle-green {
            border-color: #1d5aaa;
        }

        .circle-blue {
            border-color: #4ebd6c;
        }

        .numbers-text {
            font-size: 13px;
        }

        .number {
            color: ${props => props.theme.cardListNumbers};
            font-size: 24px;
            opacity: 1;
        }

        .text {
            font-family: 'Proxima Nova Alt', sans-serif;
            font-size: 24px;
            opacity: 1;
        }

        @media only screen and (max-width: 768px) {
            .text {
                font-size: 18px;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .intro-text {
            max-width: 600px;
            min-height: 400px;
            font-size: 24px;
            color: ${props => props.theme.white};
            text-align: center;
        }

        .card {
            min-height: 400px;
        }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .intro-text {
            margin-top: 100px;
            min-height: 300px;
        }

        .card {
            margin-top: 75px;
        }
    }
`

const NumbersBlock = () => {
    return (
        <div className="numbers">
            <h1 className="numbers-heading">Industry Energy</h1>

            <div className="numbers-area">
                <div className="numbers-circle circle-left">
                    <div className="circle circle-green">23</div>
                    <span className="numbers-text">
                        <b>You</b> Starter
                    </span>
                </div>

                <div className="numbers-circle">
                    <div className="circle circle-blue">80</div>
                    <span className="numbers-text">
                        <b>Benchmark</b> literate
                    </span>
                </div>
            </div>
        </div>
    )
}

const IntroCard = props => {
    const { descriptions, index, playvideo, innerWidth } = props
    let content = <div />
    switch (index) {
        case 0:
            content = (
                <Grid container className="intro-text" alignItems="center">
                    <Grid item xs={12}>
                        <CopyConsumer>
                            {({ copy }) => {
                                return (
                                    <div style={{ lineHeight: '32px' }}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `${copy.home.intro}`,
                                            }}
                                        />
                                    </div>
                                )
                            }}
                        </CopyConsumer>
                    </Grid>
                </Grid>
            )
            break
        case 1:
            content = (
                <Grid container className="card">
                    <Grid container className="inner-content" alignItems="center">
                        <Grid item xs={12}>
                            {descriptions.map(d => (
                                <Grid container alignItems="center" key={d.number}>
                                    <Grid item xs={1}>
                                        <div className="number">{d.number}</div>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <div className="text">{d.text}</div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <YouTube
                                        style={{ margin: '0 auto' }}
                                        videoId="sjjOmS1nYhI"
                                        width={300}
                                        height={300 / 1.77}
                                        play={() => playvideo()}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case 2:
            content = (
                <Grid container alignItems="center" className="card">
                    <Grid container className="inner-content" alignItems="center">
                        <Grid item xs={12}>
                            <CopyConsumer>
                                {({ copy }) => {
                                    return (
                                        <div style={{ lineHeight: '30px', marginBottom: '20px' }}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: `${copy.home.intro2}`,
                                                }}
                                            />
                                        </div>
                                    )
                                }}
                            </CopyConsumer>

                            <NumbersBlock />
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case 3:
            content = (
                <Grid container alignItems="center" className="card">
                    <Grid container className="inner-content" alignItems="center">
                        <Grid item xs={7}>
                            <CopyConsumer>
                                {({ copy }) => {
                                    return (
                                        <div style={{ lineHeight: '32px', fontSize: '24px' }}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: `${copy.home.intro2}`,
                                                }}
                                            />
                                        </div>
                                    )
                                }}
                            </CopyConsumer>
                        </Grid>

                        <Grid item xs={5}>
                            <NumbersBlock />
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case 4:
            content = (
                <Grid container className="card" alignItems="center">
                    <Grid container className="inner-content" alignItems="center">
                        <Grid item xs={7}>
                            {descriptions.map(d => (
                                <Grid container alignItems="center" key={d.number}>
                                    <Grid item xs={1}>
                                        <div className="number">{d.number}</div>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <div className="text">{d.text}</div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item xs={5}>
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <YouTube
                                        style={{ margin: '0 auto' }}
                                        videoId="sjjOmS1nYhI"
                                        width={300}
                                        height={300 / 1.77}
                                        play={() => playvideo()}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case 5:
            content = (
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', marginTop: '15px' }}>
                            <YouTube
                                style={{ margin: '0 auto' }}
                                videoId="qQfCx7mXbhA"
                                width={innerWidth}
                                height={innerWidth / 1.77}
                                play={() => playvideo()}
                            />
                        </div>
                    </Grid>
                </Grid>
            )
            break
        default:
            content = <React.Fragment />
    }

    return <StyledContainer>{content}</StyledContainer>
}

IntroCard.defaultProps = {
    index: 0,
    playvideo: () => {},
    innerWidth: 0,
    descriptions: [
        {
            number: '1',
            text: 'Measure your digital maturity',
        },
        {
            number: '2',
            text: 'See the competition',
        },
        {
            number: '3',
            text: 'Define your digital goals',
        },
    ],
}

IntroCard.propTypes = {
    playvideo: PropTypes.func,
    index: PropTypes.number,
    descriptions: PropTypes.array,
    innerWidth: PropTypes.number,
}

export default IntroCard
