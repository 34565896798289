import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import COMPANY_PROFILE from '../../assets/onboarding/company-profile/company-profile.jpg'
import COMPANY_PROFILE_2X from '../../assets/onboarding/company-profile/company-profile@2x.jpg'
import QUESTION from '../../assets/onboarding/question/question.jpg'
import QUESTION_2X from '../../assets/onboarding/question/question@2x.jpg'
import PRE_RESULTS from '../../assets/onboarding/pre-results/pre-results.jpg'
import PRE_RESULTS_2X from '../../assets/onboarding/pre-results/pre-results@2x.jpg'
import RESULTS from '../../assets/onboarding/results/results.jpg'
import RESULTS_2X from '../../assets/onboarding/results/results@2x.jpg'

const slideDescriptions = {
    1: 'Setup your company profile and start the survey',
    2: 'Answer questions within the areas of digitization',
    3: 'Receive feedback during your assessment process',
    4: 'Benchmark against companies within your industry',
    tip: 'Install this webapp on your mobile device.',
    tip_1: 'In the toolbar',
    tip_2: 'Tap the action icon  in the toolbar',
    tip_3: "Then select 'Add to homescreen'",
}

const MOBILE_WIDTH = 400

const StyledContainer = styled.section`
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: ${props => props.theme.mainBackground};

    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
    }

    .slide-description {
        display: flex;
        flex-direction: column;
        margin-right: 40px;

        @media only screen and (max-width: ${MOBILE_WIDTH}px) {
            align-items: center;
            margin-right: 0;
        }
    }

    .number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        margin-bottom: 25px;
        border-radius: 50%;
        border: 1px solid ${props => props.theme.onboardingCardNumber};
        font-size: 24px;
    }

    .description {
        font-size: 18px;

        @media only screen and (max-width: ${MOBILE_WIDTH}px) {
            width: 200px;
            margin-bottom: 25px;
            font-size: 16px;
            text-align: center;
        }
    }

    .image {
        height: 490px;
        border-radius: 40px;
    }
`

const OnboardingSlide = props => {
    const { index } = props
    let img
    let src
    switch (index) {
        case 0:
            img = COMPANY_PROFILE
            src = COMPANY_PROFILE_2X
            break
        case 1:
            img = QUESTION
            src = QUESTION_2X
            break
        case 2:
            img = PRE_RESULTS
            src = PRE_RESULTS_2X
            break
        case 3:
            img = RESULTS
            src = RESULTS_2X
            break
        default:
            img = COMPANY_PROFILE
            src = COMPANY_PROFILE_2X
    }

    const sliderType = window.innerWidth > 700 ? 'slider-big' : 'slider-small'
    const tallScreen = '400px'
    const shortScreen = '325px'
    return (
        <StyledContainer>
            <div className="slide-description">
                <div className="number">{index + 1}</div>
                <p className="description">{slideDescriptions[`${index + 1}`]}</p>
            </div>

            <img
                src={img}
                srcSet={`${src} 2x`}
                alt="App screen"
                className="image"
                height={sliderType === 'slider-big' ? tallScreen : shortScreen}
            />
        </StyledContainer>
    )
}

OnboardingSlide.defaultProps = {
    index: 0,
}

OnboardingSlide.propTypes = {
    index: PropTypes.number,
}

export default OnboardingSlide
