import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import logo from '../../assets/logo/logo.png'
import logo2x from '../../assets/logo/logo@2x.png'
import logo3x from '../../assets/logo/logo@3x.png'

const HeaderContainer = styled.div`
    position: relative;
    width: 100%;
    margin-top: 50px;

    .image-wrapper {
        width: 300px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    .header {
        width: 300px;
        margin: 0 auto;
        text-align: justify;
        font-size: 18px;
        color: #ffffff;
    }

    @media only screen and (min-width: 768px) {
        .image-wrapper {
            width: 350px;
        }
        .header {
            width: 330px;
        }
    }
`

const HeaderLogo = ({ text, text2 }) => {
    return (
        <HeaderContainer>
            <br />
            <div className="image-wrapper">
                <img alt="logo" src={logo} srcSet={`${logo2x} 2x,${logo3x} 3x`} />
            </div>
            {!text || text === '' ? (
                <React.Fragment />
            ) : (
                <div>
                    <br />
                    <p className="header">{text}</p>
                    {text2 ? <br /> : null}
                    {text2 ? <p className="header">{text2}</p> : null}
                    <br />
                    <br />
                </div>
            )}
        </HeaderContainer>
    )
}

HeaderLogo.defaultProps = {
    text: '',
}

HeaderLogo.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default HeaderLogo
