import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUser,
    faUsers,
    faQuestion,
    faCog,
    faSignOut,
    faHome,
    faPoll,
    faArchive,
} from '@fortawesome/pro-light-svg-icons'

import logo from '../../assets/logo/logo.png'
import logo2x from '../../assets/logo/logo@2x.png'
import logo3x from '../../assets/logo/logo@3x.png'
import UserContext from '../../contexts/User'
import { StyledButton } from '../Styles'
import { createPresurvey } from '../../api'
import { routePaths, scrollTo } from '../../utils'

const StyledAppBar = styled(AppBar)`
    && {
        background: ${props => props.theme.headerBackground};
    }

    .logo-button {
        background-color: transparent;
        border: none;
        &:focus {
            outline: 0;
        }
    }

    .appbar-link {
        font-size: 20px;
        font-family: 'Proxima Nova', sans-serif;
        color: #ffffff;
    }

    button {
        padding: 0;
        min-width: 30px;
    }

    .menu-toggle {
        width: 40px;
        height: 40px;
        cursor: pointer;
        float: right;

        &:focus {
            outline: none;
        }
    }

    .menu-toggle.on .one {
        -moz-transform: rotate(45deg) translate(7px, 7px);
        -ms-transform: rotate(45deg) translate(7px, 7px);
        -webkit-transform: rotate(45deg) translate(7px, 7px);
        transform: rotate(45deg) translate(7px, 7px);
    }

    .menu-toggle.on .two {
        opacity: 0;
    }

    .menu-toggle.on .three {
        -moz-transform: rotate(-45deg) translate(8px, -10px);
        -ms-transform: rotate(-45deg) translate(8px, -10px);
        -webkit-transform: rotate(-45deg) translate(8px, -10px);
        transform: rotate(-45deg) translate(8px, -10px);
    }

    .one,
    .two,
    .three {
        width: 80%;
        height: 3px;
        background: ${props => props.theme.mainTextColor};
        margin: 8px auto;
        backface-visibility: hidden;
        -moz-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }
`

const StyledSideList = styled.div`
    height: 100%;
    width: 260px;
    overflow-x: hidden;
    background-color: ${props => props.theme.headerBackground};

    .top-row {
        display: flex;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        color: ${props => props.theme.mainTextColor};
    }

    .user-icon {
        display: block;
        width: 50px;
        height: 50px;
        padding: 10px;
        margin-right: 20px;
        border: 2px solid ${props => props.theme.mainTextColor};
        border-radius: 50%;
        text-align: center;
    }

    .user-name {
        font-size: 18px;
    }

    button {
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        border-bottom: 1px solid ${props => props.theme.preResultsBorder};
        font-weight: lighter;
        color: ${props => props.theme.mainTextColor};
        text-transform: none;

        .menu-item {
            width: 100%;
            font-size: 20px;
            padding-bottom: 5px;

            .text {
                vertical-align: top;
                text-align: left;
                float: left;
            }

            .icon {
                text-align: right;
                float: right;
            }
        }
    }

    .bottom-row {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
    }

    .survey-button {
        margin-top: -5px;
        margin-left: 10px;
    }
`

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        cursor: 'pointer',
        marginRight: 10,
    },
    menuButton: {
        borderRadius: '3px',
        margin: '0 10px 0 10px',
    },
    toolbar: theme.mixins.toolbar,
    zIndex: 99999,
    noPaddiing: {
        padding: 0,
    },
})

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
})

const desktopMenuBarThreshold = 1112

class MainAppBar extends React.Component {
    static contextType = UserContext

    state = {
        open: false,
        desktop: window.innerWidth > desktopMenuBarThreshold,
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    navTo = value => () => {
        const { history } = this.props
        scrollTo(0, 0)
        this.setState({ open: false })
        history.push(routePaths[value])
    }

    resize = () => {
        this.setState({
            desktop: window.innerWidth > desktopMenuBarThreshold,
        })
    }

    toggleDrawer = open => () => {
        this.setState({
            open,
        })
    }

    handleLogout = () => {
        const { logout } = this.context
        logout()
    }

    startNewSurvey = async () => {
        const { user } = this.context
        const res = await createPresurvey({ userId: user.userId })
        if (res.error) {
            return
        }

        const { history } = this.props
        history.push({
            pathname: routePaths.presurvey,
            state: { presurveyId: res.data.presurveyId },
        })
    }

    render() {
        const { classes } = this.props
        const { user } = this.context
        const { open, desktop } = this.state
        return (
            <div className={classes.root} id="appBar">
                <MuiThemeProvider theme={theme}>
                    <StyledAppBar position="fixed">
                        <Toolbar>
                            <button
                                className="logo-button"
                                type="button"
                                onClick={this.navTo('home')}
                            >
                                <img
                                    className={classes.logo}
                                    alt="logo-button"
                                    height="32"
                                    src={logo}
                                    srcSet={`${logo2x} 2x,${logo3x} 3x`}
                                />
                            </button>
                            <div className={classes.grow} />

                            {desktop ? (
                                <>
                                    <MenuItem
                                        onClick={this.navTo('home')}
                                        className={classes.menuButton}
                                    >
                                        <div className="appbar-link">Home</div>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.navTo('history')}
                                        className={classes.menuButton}
                                    >
                                        <div className="appbar-link">History</div>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.navTo('about')}
                                        className={classes.menuButton}
                                    >
                                        <div className="appbar-link">About DAI</div>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.navTo('contact')}
                                        className={classes.menuButton}
                                    >
                                        <div className="appbar-link">Contacts</div>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.navTo('settings')}
                                        className={classes.menuButton}
                                    >
                                        <div className="appbar-link">Account Settings</div>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.handleLogout}
                                        className={classes.menuButton}
                                    >
                                        <div className="appbar-link">Logout</div>
                                    </MenuItem>

                                    <StyledButton
                                        customClass="narrower bionic-green survey-button"
                                        onClick={this.startNewSurvey}
                                    >
                                        Start New Survey
                                    </StyledButton>
                                </>
                            ) : (
                                <Button onClick={this.toggleDrawer(!open)}>
                                    <div className={`menu-toggle ${open ? 'on' : ''}`}>
                                        <div className="one" />
                                        <div className="two" />
                                        <div className="three" />
                                    </div>
                                </Button>
                            )}
                        </Toolbar>
                    </StyledAppBar>
                    <div className={classes.toolbar} />

                    <SwipeableDrawer
                        anchor="right"
                        open={open}
                        onOpen={this.toggleDrawer(true)}
                        onClose={this.toggleDrawer(false)}
                        ModalProps={{
                            style: {
                                zIndex: 99,
                                top: styles.toolbar,
                            },
                        }}
                    >
                        <StyledSideList>
                            <div className={classes.toolbar} />
                            <Grid container spacing={8} alignItems="center">
                                <Grid item xs={12}>
                                    <div className="top-row">
                                        <div className="user-icon">
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                        <div className="user-name">
                                            {user.givenName || 'username'}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonBase onClick={this.startNewSurvey}>
                                        <div className="menu-item">
                                            <div className="text">Start new survey</div>
                                            <FontAwesomeIcon className="icon" icon={faPoll} />
                                        </div>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonBase onClick={this.navTo('home')}>
                                        <div className="menu-item">
                                            <div className="text">Home</div>
                                            <FontAwesomeIcon className="icon" icon={faHome} />
                                        </div>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonBase onClick={this.navTo('history')}>
                                        <div className="menu-item">
                                            <div className="text">History</div>
                                            <FontAwesomeIcon className="icon" icon={faArchive} />
                                        </div>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonBase onClick={this.navTo('contact')}>
                                        <div className="menu-item">
                                            <div className="text">Contacts</div>
                                            <FontAwesomeIcon className="icon" icon={faUsers} />
                                        </div>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonBase onClick={this.navTo('about')}>
                                        <div className="menu-item">
                                            <div className="text">About DAI</div>
                                            <FontAwesomeIcon className="icon" icon={faQuestion} />
                                        </div>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonBase onClick={this.navTo('settings')}>
                                        <div className="menu-item">
                                            <div className="text">Account Settings</div>
                                            <FontAwesomeIcon className="icon" icon={faCog} />
                                        </div>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonBase onClick={this.handleLogout}>
                                        <div className="menu-item">
                                            <div className="text">Logout</div>
                                            <FontAwesomeIcon className="icon" icon={faSignOut} />
                                        </div>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </StyledSideList>
                    </SwipeableDrawer>
                </MuiThemeProvider>
            </div>
        )
    }
}

MainAppBar.defaultProps = {
    history: {},
}

MainAppBar.propTypes = {
    history: PropTypes.object,
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MainAppBar)
