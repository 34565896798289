import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as qs from 'query-string'

import { routePaths } from '../utils'
import { fetchSurvey, submitSurveyAnswer, deleteSurveyAnswer } from '../api'
import UserContext from '../contexts/User'
import { Page, PageLoad } from '../components/Page'
import SurveyContainer from '../components/Survey'
import { FeedbackBar, FeedbackBarContentWrapper } from '../components/Feedback'
import SimpleDialog from '../components/SimpleDialog'
import { StyledSpinner } from '../components/Styles'

const StyledPage = styled.div``

class SurveyPage extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.state = {
            blocks: null,
            display: 'list',
            loading: true,
            surveyId: null,
        }
    }

    async componentDidMount() {
        const { history } = this.props
        // if there is no surveyId in state, redirect home
        if (!history.location.state || !history.location.state.surveyId) {
            history.push(routePaths.home)
            return
        }

        // fetch the survey and set the blocks
        const { surveyId } = history.location.state
        const res = await fetchSurvey(surveyId)
        if (res.status !== 200) {
            history.push(routePaths.home)
            return
        }
        const b = res.data.blocks
        const blocks = this.setBlocks(b)

        // check the url for a block index
        // if index exists, set it as a block index to identify current block
        // if block index exists, display questions of given block
        // otherwise display block overview list
        const blockQuery = qs.parse(history.location.search)
        let blockIndex = -1
        if (blockQuery) {
            const blockId = blockQuery.block
            for (let i = 0; i < blocks.length; i += 1) {
                if (blocks[i].blockId.toString() === blockId) {
                    blockIndex = i
                }
            }
        }

        this.setState({
            surveyId,
            blocks,
            profile: res.data.profile,
            currentBlock: blockIndex !== -1 ? blocks[blockIndex] : blocks[0],
            loading: false,
            display: blockIndex !== -1 ? 'questions' : 'list',
        })

        // track when back button is clicked and check routes
        window.onpopstate = () => {
            this.onRouteChanged()
        }
    }

    // in each block, if a question has an answer, identify the selected option
    setBlocks = blocks => {
        const updatedBlocks = blocks.map(b => {
            b.questions.forEach(q => {
                if (q.answer) {
                    q.options.forEach(o => {
                        if (o.optionId === q.answer.optionId) {
                            Object.assign(o, { selected: true })
                        } else {
                            Object.assign(o, { selected: false })
                        }
                    })
                }
            })
            return b
        })
        return updatedBlocks
    }

    // show block overview or block questions depending on current url
    // set current block based on current url
    onRouteChanged = () => {
        const { history } = this.props
        const { blocks } = this.state
        const blockQuery = qs.parse(history.location.search)
        let blockIndex = -1
        if (blockQuery) {
            const blockId = blockQuery.block
            for (let i = 0; i < blocks.length; i += 1) {
                if (blocks[i].blockId === blockId) {
                    blockIndex = i
                }
            }
        }
        this.setState({
            currentBlock: blockIndex !== -1 ? blocks[blockIndex] : blocks[0],
            display: blockIndex !== -1 ? 'questions' : 'list',
        })
    }

    // submits or updates an answer for a quesetion in a block
    updateBlock = async (block, answer) => {
        const { surveyId, blocks } = this.state
        let { optionId, deselect, ambition, importance } = answer
        let res = null
        if (!deselect) {
            res = await submitSurveyAnswer(surveyId, answer.questionId, {
                optionId,
                ambition,
                importance,
            })
        } else {
            res = await deleteSurveyAnswer(surveyId, answer.questionId)
        }
        if (res.status !== 200) {
            this.setState({
                feedbackMessage: {
                    type: 'error',
                    text: 'An error occurred, your response was not recorded.',
                },
            })
            return
        }

        // updates a block with the new answer
        // updates all blocks
        const updatedBlocks = blocks.map(b => {
            if (b.blockId === block.blockId) {
                b.questions.forEach(q => {
                    if (q.questionId === answer.questionId) {
                        Object.assign(q, { ambition: answer.ambition })
                        Object.assign(q, { importance: answer.importance })
                        if (
                            q.answer &&
                            (q.answer.optionId === answer.optionId || answer.deselect)
                        ) {
                            Object.assign(q, { answer: false })
                            if (block.preresults) {
                                Object.assign(b, { preresults: null })
                            }
                        } else {
                            Object.assign(q, { answer })
                        }
                        delete answer.deselect
                        q.options.forEach(o => {
                            if (q.answer && o.optionId === optionId) {
                                Object.assign(o, { selected: true })
                            } else {
                                Object.assign(o, { selected: false })
                            }
                        })
                    }
                })
            }
            return Object.assign({}, b)
        })
        this.setState({
            feedbackMessage: null,
            blocks: updatedBlocks,
        })
    }

    // sets the current block and navigates url to display the block questions
    setCurrentBlock = block => {
        const { history } = this.props
        this.setState({
            loading: false,
            currentBlock: block,
            display: 'questions',
        })
        history.push(`${routePaths.survey}?block=${block.blockId}`)
    }

    // sets the currentblock
    updateCurrentBlock = block => {
        this.setState({
            currentBlock: block,
        })
    }

    // when a block is completed
    // 1. retrieve the survey with updated blocks
    // 2. set the survey blocks
    // 3. set the current block
    // 4. display the block overview
    retrieveBlockResults = async block => {
        const $this = this
        const { history } = this.props
        const { blocks, surveyId } = this.state
        this.setState({
            loadingResults: true,
        })
        const res = await fetchSurvey(surveyId)
        if (res.status > 201) {
            return
        }
        const filterBlocks = res.data.blocks.filter(b => b.blockId === block.blockId)
        const [newBlock] = filterBlocks
        const { preresults, bestPractices, benchmark } = newBlock
        block.preresults = preresults
        // for testing edge case of N/A block
        // block.preresults = { userScore: -100, descriptor: 'N/A' }
        block.bestPractices = bestPractices
        block.benchmark = benchmark
        const updatedBlocks = blocks.map(b => {
            if (b.blockId === block.blockId) {
                b.bestPractices = block.bestPractices
                b.preresults = block.preresults
                b.benchmark = block.benchmark
            }
            return Object.assign({}, b)
        })
        $this.setState(
            {
                blocks: updatedBlocks,
                currentBlock: block,
                display: 'list',
                loading: false,
                loadingResults: false,
            },
            () => {
                history.push(routePaths.survey)
            }
        )
    }

    // switch between block overview and current block questions
    toggleBlockList = () => {
        const { display, currentBlock } = this.state
        const { history } = this.props
        const currentDisplay = display === 'list' ? 'questions' : 'list'
        this.setState({ display: currentDisplay, currentBlock })
        if (currentDisplay === 'list') {
            history.push(`${routePaths.survey}`)
        } else {
            history.push(`${routePaths.survey}?block=${currentBlock.blockId}`)
        }
    }

    // navigate to results page
    goToResults = () => {
        const { history } = this.props
        const { surveyId } = this.state
        history.push({
            pathname: routePaths.results,
            state: { surveyId },
        })
    }

    closeFeedbackMessage = () => {
        this.setState({
            feedbackMessage: null,
        })
    }

    render() {
        const {
            blocks,
            currentBlock,
            display,
            loading,
            loadingResults,
            feedbackMessage,
            profile,
        } = this.state

        return (
            <Page {...this.props} appbar>
                {!loading ? (
                    <StyledPage>
                        <SurveyContainer
                            profile={profile}
                            blocks={blocks}
                            updateBlock={this.updateBlock}
                            retrieveBlockResults={this.retrieveBlockResults}
                            currentBlock={currentBlock}
                            display={display}
                            setCurrentBlock={this.setCurrentBlock}
                            updateCurrentBlock={this.updateCurrentBlock}
                            toggleBlockList={this.toggleBlockList}
                            goToResults={this.goToResults}
                        />
                    </StyledPage>
                ) : (
                    <PageLoad />
                )}

                {feedbackMessage && (
                    <FeedbackBar open={!!feedbackMessage}>
                        <FeedbackBarContentWrapper
                            message={feedbackMessage.text}
                            variant={feedbackMessage.type}
                            onClose={this.closeFeedbackMessage}
                        />
                    </FeedbackBar>
                )}

                <SimpleDialog dialogOpen={loadingResults} closeDialog={() => {}} noClose>
                    <div style={{ textAlign: 'center' }}>
                        <div>Loading your results</div>
                        <br />
                        <StyledSpinner />
                    </div>
                </SimpleDialog>
            </Page>
        )
    }
}

SurveyPage.defaultProps = {
    history: {},
}

SurveyPage.propTypes = {
    history: PropTypes.object,
}

export default SurveyPage
