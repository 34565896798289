import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Select, SingleSelect, TextInput } from '../OptionSelectElements'
import { CardHead } from '../Cards'
import { StyledButton } from '../Styles'

const StyledSection = styled.div`
    max-width: 820px;
    margin: 20px auto;
    padding: 15px;

    .section-inner {
        max-width: 820px;
        margin: 0 auto;
        padding: 1rem;
    }

    .unhighlight {
        background-color: ${props => props.theme.grey} !important;

        &:hover {
            background-color: ${props => props.theme.darkgrey} !important;
        }
    }

    .next-button {
        width: 120px;
        padding: 1rem !important;
        margin: 2rem 1rem 1rem 0;
    }
`

class PreSurveySection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showNextButton: false,
        }
        this.nextButton = React.createRef()
        this.selectRef = React.createRef()
    }

    inFocus = () => {
        const { updateIndex, question, progressState } = this.props
        updateIndex(question.index)
        progressState(false)
    }

    focusSection = () => {
        const { updateIndex, question } = this.props
        updateIndex(question.index)
    }

    isBlur = () => {
        const { progressState } = this.props
        progressState(true)
    }

    handleChange = answer => {
        const { question, updateQuestion, index, next } = this.props
        // pass the change up to container to update question with answer
        question.answer = answer
        updateQuestion(question, index)
        let showNextButton = false
        if (question.type === 'multi_select') {
            showNextButton = true
        }
        this.setState({ showNextButton, highlightNext: true }, () => {
            if (question.type !== 'multi_select' && answer.length > 0) {
                const timer = setTimeout(() => {
                    next(question)
                    clearTimeout(timer)
                }, 200)
            }
        })
    }

    next = e => {
        const { next, question } = this.props
        e.preventDefault()
        this.nextButton.current.elements[0].blur()
        next(question)
        this.setState({ highlightNext: false })
    }

    render() {
        const { showNextButton, highlightNext } = this.state
        const { question, id, currentIndex, index, searchCompany } = this.props
        let questionType = null

        if (question.type === 'single_select' && question.options.length <= 10) {
            questionType = (
                <SingleSelect
                    short
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                    inFocus={this.inFocus}
                    isBlur={this.isBlur}
                />
            )
        } else if (question.type === 'single_select' && question.options.length > 10) {
            questionType = (
                <Select
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                    inFocus={this.inFocus}
                    isBlur={this.isBlur}
                />
            )
        }
        if (question.type === 'single_select_createable' && question.options.length > 8) {
            questionType = (
                <Select
                    loadOptions={searchCompany}
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                    inFocus={this.inFocus}
                    isBlur={this.isBlur}
                    creatable
                />
            )
        }
        if (question.type === 'long_single_select') {
            questionType = (
                <SingleSelect
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                />
            )
        }
        if (question.type === 'multi_select') {
            questionType = (
                <Select
                    ref={this.selectRef}
                    question={question}
                    multipleSelect
                    selected={this.handleChange}
                    inFocus={this.inFocus}
                    isBlur={this.isBlur}
                />
            )
        }
        if (question.type === 'text_input') {
            questionType = (
                <TextInput
                    ref={this.selectRef}
                    question={question}
                    selected={this.handleChange}
                    name={question.dependency_tag}
                    value={question.answer && question.answer[0] ? question.answer[0].value : ''}
                />
            )
        }

        // certain questions depend on a year
        // these questions need to be updated dynamically based on the user's response
        let { title } = question
        if (question.dependencies && question.dependencies.includes('year') && question.yearValue) {
            title = `${question.title.split('?')[0]} in ${question.yearValue}?`
        }

        return (
            <StyledSection ref={id}>
                <TweenOne
                    key="0"
                    className="section-inner"
                    animation={{
                        opacity:
                            currentIndex === question.index ||
                            (currentIndex === 0 && question.index === 1)
                                ? 1
                                : 0.3,
                        duration: 150,
                    }}
                >
                    <TweenOne onClick={this.focusSection} animation={{ opacity: 1 }}>
                        <CardHead
                            title={title}
                            indicator={`${index}.`}
                            optional={question.optional}
                        />
                    </TweenOne>

                    <TweenOne animation={{ opacity: 1 }}>{questionType}</TweenOne>

                    {showNextButton ? (
                        <QueueAnim key="anim" type="left">
                            <form onSubmit={this.next} ref={this.nextButton}>
                                <StyledButton
                                    type="button"
                                    className={`next-button ${
                                        highlightNext ? ' highlight' : ' unhighlight'
                                    }`}
                                    onClick={this.next}
                                >
                                    next
                                </StyledButton>
                            </form>
                        </QueueAnim>
                    ) : null}
                </TweenOne>
            </StyledSection>
        )
    }
}

PreSurveySection.defaultProps = {
    updateQuestion: () => {},
    question: {},
    updateIndex: () => {},
    progressState: () => {},
    index: 0,
    next: () => {},
    id: 0,
    currentIndex: 0,
}

PreSurveySection.propTypes = {
    updateQuestion: PropTypes.func,
    question: PropTypes.object,
    updateIndex: PropTypes.func,
    progressState: PropTypes.func,
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    next: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default PreSurveySection
