import React from 'react'
import styled from 'styled-components'

import LEVELS_REFERENCE from '../../references/maturity_levels.json'

const TABLET_WIDTH = 768

const isOdd = (it: number) => it % 2 !== 0

const StyledDigitalMaturity = styled.section`
    margin-bottom: 100px;

    .stages-section {
        display: flex;
        margin-bottom: 100px;
    }

    .no-margin-bottom {
        margin-bottom: 0;
    }

    .stages-description {
        flex: 1 0 390px;

        @media only screen and (max-width: ${TABLET_WIDTH}px) {
            flex-basis: 70px;
        }
    }

    .heading {
        margin-bottom: 15px;
        font-size: 18px;
        text-transform: uppercase;
    }

    .text {
        font-size: 16px;
    }

    .digital-maturity-title {
        margin-bottom: 45px;
        font-size: 24px;
        font-weight: normal;
        text-align: center;
    }

    .digital-maturity-content {
        color: white;
    }

    .divider-area {
        position: relative;
        margin-left: 30px;
        margin-right: 30px;
    }

    .divider {
        position: absolute;
        left: 0;
        width: 1px;
        height: calc(100% + 100px);
        background-color: ${props => props.theme.stageDivider};
    }

    .circle {
        position: absolute;
        top: 0;
        left: -15px;
        z-index: 10;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: ${props => props.theme.stageDividerCircle};
    }
`

const DigitalMaturity = () => {
    return (
        <StyledDigitalMaturity>
            <h2 className="digital-maturity-title">Digital maturity in 4 stages</h2>

            {LEVELS_REFERENCE.levels.map((level, i, arr) => (
                <article
                    key={level.levelId}
                    className={`stages-section ${i === arr.length - 1 ? 'no-margin-bottom' : ''}`}
                >
                    {isOdd(i) ? (
                        <div className="stages-description">
                            <h3 className="heading">{level.title}</h3>
                            <p className="text">{level.text}</p>
                        </div>
                    ) : (
                        <div className="stages-description">
                            <h3 className="heading">{`Stage ${i + 1}`}</h3>
                        </div>
                    )}

                    <div className="divider-area">
                        <div className="circle" />
                        {i < arr.length - 1 && <div className="divider" />}
                    </div>

                    {isOdd(i) ? (
                        <div className="stages-description">
                            <h3 className="heading">{`Stage ${i + 1}`}</h3>
                        </div>
                    ) : (
                        <div className="stages-description">
                            <h3 className="heading">{level.title}</h3>
                            <p className="text">{level.text}</p>
                        </div>
                    )}
                </article>
            ))}
        </StyledDigitalMaturity>
    )
}

export default DigitalMaturity
